.rally-stage__gpx-map {
    z-index: 1;
}

.rally-stage__icon {
    font-size: calc(96rem /16);
    color: var(--color-primary);
}

@media screen and (max-width: 767px) {
    .rally-stage__icon {
        font-size: calc(40rem /16);
    }
}