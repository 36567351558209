.outtra-modal-trigger-button {
    height: calc(48rem / 16) !important;
    padding: 0 !important;

    @media screen and (min-width: 768px) {
        height: calc(60rem / 16) !important;
    }

    .outtra-modal-trigger-button {
        background: #fff;
        color: var(--color-primary)
    }

    &, &:hover {
        border: 1px solid transparent;
        border-radius: .25rem;
        box-shadow: 0 .625rem 3.125rem 0 rgba(0, 58, 96, .1);
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.21429;
        text-align: center;
        text-transform: uppercase;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    &:hover {
        background: var(--color-primary-light);
        color: #fff
    }
}