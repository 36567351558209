.main-navbar {
    position: absolute;
    left: 0;
    right: 0;
    transition: background 200ms ease-in-out;
    color: #fff;
    z-index: 12;

    @media screen and (min-width: 768px) {
        transition: background 200ms ease-in-out, color 200ms ease-in-out;
    }
}
.main-navbar--dark {
    color: var(--color-primary);
}
.main-navbar--relative {
    position: relative;
}
.main-navbar.is-affix,
.main-navbar--relative {
    top: 0;
    background: #fff;
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        color: var(--color-text-default);
    }
}
@media screen and (min-width: 768px) {
    .main-navbar:hover {
        background: #fff;
        color: var(--color-text-default);
    }
}
.main-navbar.is-affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 calc(2rem / 16) rgba(0,0,0,.12);
}
.main-navbar__container {
    max-width: calc(1680rem / 16);
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border-bottom: calc(1rem / 16) solid transparent;
    transition: border-bottom-color 200ms ease-in-out;
}
@media screen and (min-width: 768px) {
    .main-navbar:hover .main-navbar__container,
    .main-navbar--relative .main-navbar__container {
        border-bottom-color: var(--color-sand-20);
    }
}
.main-navbar__logo,
.main-navbar__right {
    @media screen and (min-width: 768px) {
        flex: 0 0 auto;
    }

    @media screen and (min-width: 1680px) {
        width: 25%;
    }
}
.main-navbar__right {
    @media screen and (min-width: 1400px) {
        width: 25%;
    }
}
.main-navbar__logo {
    display: flex;
    position:relative;
    @media screen and (min-width: 768px) {
        padding-left: calc(18rem / 16);
    }
}
.main-navbar__logo__img {
    height: auto;
    width: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        color: #fff;
    }
}
.main-navbar__sub-logo__additional-text {
    position: absolute;
    top:.25rem;
    white-space: nowrap;
    display: block;
    height:.875rem;
    font-family: var(--font-default-compact);
    text-transform: uppercase;
    font-size: .875rem;
}
.main-navbar__sub-logo__img {
    display: inline-block;
    height: calc(24rem / 16);
    width: auto;
    padding:.25rem 0;
    margin-left:1rem;
    margin-top:1.375rem;

    @media screen and (min-width: 768px) {
        height: calc(30rem / 16);
        margin-top:1.5rem;
        margin-left:2rem;
    }
}
@media screen and (min-width: 1200px) {
    .main-navbar:not(.main-navbar--relative) .main-navbar__logo__img {
        transform: scale(1.6666) translateY(20%) translateX(52%);
        transition: transform 200ms ease-in-out, fill 200ms ease-in-out;
    }
}
@media (hover: hover) {
    .main-navbar:hover .main-navbar__logo__img {
        color: var(--color-primary);
        transform: scale(1) translateY(0) translateX(0);
    }
}
.main-navbar.is-affix .main-navbar__logo__img {
    color: var(--color-primary);

    @media screen and (min-width: 1200px) {
        transform: scale(1) translateY(0) translateX(0);
    }
}

.main-navbar--relative .main-navbar__logo__img {
    color: var(--color-primary);
}

.main-navbar__search {
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        width: calc(160rem / 16);
        color: currentColor;
    }

    @media screen and (min-width: 1400px) {
        width: calc(250rem / 16);
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        right: 0;
        left: 0;
        top: 100%;
        background: #fff;
        visibility: hidden;
        opacity: 0;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        padding: calc(20rem / 16);
        box-shadow: calc(10rem / 16) calc(20rem / 16) calc(20rem / 16) 0 rgba(0,0,0,.1);
    }
}
@media screen and (max-width: 767px) {
    .is-open > .main-navbar__search {
        visibility: visible;
        opacity: 1;
    }
}
.main-navbar__right {
    @media screen and (min-width: 768px) {
        position: relative;
        padding-right: calc(10rem / 16);
    }
}
.main-navbar__right__item {
    padding: 0 calc(10rem / 16);
    line-height: 0.8;

    @media screen and (min-width: 768px) {
        padding: 0 calc(3rem / 16);
    }
}
.main-navbar__right__item + .main-navbar__right__item {
    margin-left: calc(10rem / 16);

    @media screen and (min-width: 1400px) {
        margin-left: calc(32rem / 16);
    }
}
.main-navbar__right__link {
    background: none;
    border: 0;
    color: currentColor;
    font-size: calc(18rem / 16);
    display: inline-block;
    position: relative;
    padding: calc(8rem / 16) calc(1em / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.main-navbar__right__link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(2rem / 16);
    background: currentColor;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.main-navbar__right__item:hover .main-navbar__right__link:after {
    opacity: 1;
}

/* checkout */
.main-navbar__main__infotext {
    padding: 2.4375rem 1rem;
}