.pimcore_area_story-hub-teaser {
    margin-top: 3rem;
}

.js-teaser-slider .slick-track {
    margin-left: -0.75rem !important;
    display: flex !important;
    @media screen and (min-width: 768px) {
        margin-left: 0 !important;
    }
}

.js-teaser-slider .slick-slide {
    height: inherit !important;
    display: flex;
    flex-direction: column;
}

.js-teaser-slider .slick-slide  + .slick-slide {
    margin-left: calc(12rem / 16);
}

.story-hub__author {
    font-size: 14px;
}

.story-hub__sort-select {
    display: inline-block;
    width: auto;
    font-size: .75rem;
    padding: 0;
    padding-right: calc(20rem / 16);
    background: transparent;
    text-align-last: right;
}

.story-hub__sort-select-wrapper {
    position: relative;
}
.story-hub__sort-select-wrapper:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(8rem / 16);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    color: var(--color-primary);
}