.custom-checkbox:not(.custom-checkbox--color) {
    position: relative;
    padding-left: 1.7em;
}

.custom-checkbox:not(.custom-checkbox--color):before,
.custom-checkbox:not(.custom-checkbox--color):after {
    content: " ";
    display: table;
}

.custom-checkbox:not(.custom-checkbox--color):after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox .custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 1.25em;
    height: 1.25em;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__text {
    display: block;
    font-size: .875rem;
    overflow: hidden;
    margin-top: .1em;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__text a {
    text-decoration: underline;
}
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__text a:hover,
.custom-checkbox:not(.custom-checkbox--color) .custom-checkbox__text a:focus {
    text-decoration: none;
}


/* inline */
.form-check-inline .form-check-input {
    position: absolute; /* override bootstrap */
}


/* color */
.custom-checkbox--color{
    display: inline-block;
    margin:0 1.25rem 1.5rem 0;
}

.custom-checkbox--color label{
    border-radius: 50%;
    position: relative;
    height: 2rem;
    width: 2rem;
}

.custom-checkbox--color .custom-checkbox--color__active{
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-radius: 50%;
    opacity: 0;
}
.custom-checkbox--color .custom-checkbox--color__active:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    border-radius: 50%;
}
.custom-checkbox--color input:checked ~ .custom-checkbox--color__active{
    opacity: 1;
}
.custom-checkbox--color .custom-checkbox__box:before{
    content: '';
}

.custom-checkbox--color .sr-only {
    background-color: white !important;
    color: black !important;
}