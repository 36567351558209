.text-banner__title {
    margin-bottom: calc(-25rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-32rem / 16);
    }
}
.text-banner__content {
    background: var(--color-sand-20);
    padding: calc(40rem / 16) calc(30rem / 16);
    margin-left: calc(30rem / 16);
    margin-right: calc(-20rem / 16);

    @media screen and (min-height: 768px) {
        margin: 0;
        padding: calc(65rem / 16) calc(20rem / 16);
    }
}
.text-banner__content-container {
    max-width: calc(538rem / 16);
}