.deu__hero-slider2,
.deu__hero-slider2 .embed-responsive-cover {
    min-height: 100vh;
}

.deu__hero-slider2 .embed-responsive-cover img.embed-responsive-item {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transform: none;
    object-fit: cover;
}

.deu__hero-slider2__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    z-index: 2;
    pointer-events: none;
}

.deu__hero-slider2__overlay-img-right,
.deu__hero-slider2__overlay-img-left {
    position: absolute;
    top: 50%;
    right: 0;

    z-index: 1;
    transform: translateY(-50%);

    @media (min-width: 768px) {
        bottom: calc(40rem/16);
        top: auto;
        transform: none;
    }
}

.deu__hero-slider2__overlay-img-left {
    right: auto;
    left: 0;
}

.deu__hero-slider2__overlay-img-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    z-index: 2;

    @media (min-width: 768px) {
        max-width: 80%;
        height: auto;

        top: auto;
    }
}

.js-hero-slider2__arrow {
    cursor: pointer;
}
