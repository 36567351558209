.cart-teaser {
    font-size: calc(14rem / 16);
    padding: calc(10rem / 16) 0;
    position: relative;
    border-top: calc(1rem / 16) solid var(--color-sand-20);
}
.cart-teaser:last-child {
    border-bottom: calc(1rem / 16) solid var(--color-sand-20);
}
.cart-teaser__title {
    margin-top: calc(2rem / 16);
    font-size: calc(14rem / 16);
    line-height: calc(18/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.cart-teaser__img-container {
    background: var(--color-sand-20);
    padding: calc(20rem / 16);
}
.cart-teaser__img {
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: transform 200ms ease-in-out;
}
.cart-teaser__price {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.cart-teaser__remove {
    background: none;
    border: none;
    color: var(--color-sand);
    transition: transform 200ms ease-in-out;
    position: absolute;
    right: 0;
    top: calc(10rem / 16);
    font-size: calc(12rem / 16);
    z-index: 2;
}
.cart-teaser__remove:hover,
.cart-teaser__remove:focus {
    color: var(--color-primary);
}