html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default-xs);
    word-break: break-word;
    line-height: calc(22/14);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
        line-height: calc(24/16);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong,
.font-default-bold {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}

.font-default-compact {
    font-family: var(--font-default-compact);
    font-weight: var(--font-default-compact-weight);
    font-style: var(--font-default-compact-style);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: calc(20rem / 16);
    display: block;
    font-family: var(--font-default-compact);
    font-weight: var(--font-default-compact-weight);
    font-style: var(--font-default-compact-style);
    text-transform: uppercase;
    color: var(--color-primary);
}

h1, .h1 {
    font-size: calc(40rem/16);
    line-height: 1;
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(70rem/16);
    }
}
h2, .h2 {
    font-size: calc(42rem/16);
    line-height: 1;
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(56rem/16);
    }
}
h3, .h3 {
    font-size: calc(20rem/16);
    line-height: 1;
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
        letter-spacing: calc(1rem / 16);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    line-height: 1;
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem/16);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
    line-height: 1;
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
h6, .h6 {
    font-size: calc(16rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
}

.no-hl-style {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    font-style: normal;
    text-transform: none;
    color: inherit;
}
table{
    word-break: initial;
}

.text-muted-dark {
    color: var(--color-text-muted-dark);
}