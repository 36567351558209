.anchor__link{
    user-select: none;
    pointer-events: none;
    display: block;
}

:target::before,
.anchor__link:target::before {
    content: "";
    display: block;
    height: 7.25rem;
    margin: -7.25rem 0 0;
}

.pimcore_area_anchor + .pimcore_area_content > .slide {
    margin-top: 0;
}