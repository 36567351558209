.compare-item {
    border-radius: calc(4rem / 16);
    background-color: #fff;
    box-shadow: 0 0 calc(10rem / 16) 0 rgba(0,58,96,0.1);
    padding: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }
}
.compare-item__img-top {
    position: absolute;
    top: calc(20rem / 16);
    left: calc(20rem / 16);
    right: calc(20rem / 16);
}
.compare-item__remove {
    font-size: calc(20rem / 16);
}
.compare-item__price {
    font-size: calc(20rem / 16);
}
.compare-item__price__currency {
    font-size: calc(16rem / 16);
}
.compare-item__box {
    font-size: calc(14rem / 16);
    line-height: calc(22/14);
    padding: calc(20rem / 16);
}
.compare-item__box--disabled {
    opacity: .1;
}
.compare-item__add {
    font-size: calc(50rem / 16);
}
.compare-btn {
    padding: 0;
    background: none;
    border: 0;
    font-size: calc(14rem / 16);
    text-decoration: none;
}
.compare-btn.is-active .compare-btn__compare,
.compare-btn__compare--remove {
    display: none;
}
.compare-btn__compare,
.compare-btn.is-active .compare-btn__compare--remove {
    display: inline;
}
.compare-btn__icon {
    margin-right: calc(5rem / 16);
    vertical-align: -.1em;
}
.compare-btn:hover {
    text-decoration: underline;
}