.video-js__playbtn, .asset-video{
    cursor: pointer;
}

.video-js__playbtn, .asset-video__play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    width: calc(50rem/16);
    height: calc(50rem/16);
    background: rgba(0,58,96,0.3);
    border: calc(1rem / 16) solid #fff;
    color: #fff;
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all .25s ease;

    @media screen and (min-width: 768px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
        font-size: calc(16rem/16);
    }
}

.asset-video__poster {
    z-index: 1;
}
.is-playing .asset-video__poster,
.is-playing .asset-video__play {
    display: none;
}

video.ratio-item.js-video__video {
    background: transparent !important;
}

video.ratio-item.js-video__video--has-black-background {
    background: black !important;
}

/*!* Hide the custom play btn on Firefox and Safari because they have already a default play btn #56560 *!*/
/*!*https://www.browserstack.com/guide/create-browser-specific-css*!*/
/*!* Firefox *!*/
/*@supports (-moz-appearance:none) {*/
/*    .asset-video__play {*/
/*        display: none;*/
/*    }*/
/*}*/

/*!* Safari 11+ *!*/
/*@supports (-webkit-appearance:none) and (stroke-color:transparent) {*/
/*    .asset-video__play {*/
/*        display: none;*/
/*    }*/
/*}*/

/*!* Safari 10.1 *!*/
/*@media not all and (min-resolution:.001dpcm){*/
/*    @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {*/
/*        .asset-video__play {*/
/*            display: none;*/
/*        }*/
/*    }*/
/*}*/

/*!* Safari 6.1-10.0 (but not 10.1) *!*/
/*@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){*/
/*    @media {*/
/*        .asset-video__play {*/
/*            display: none;*/
/*        }*/
/*    }}*/
