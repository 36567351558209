.langmodal-switch,
.lang-switch.lang-switch{
    position: relative;
    padding: 0 0 0 1.5rem;
    text-align: left;
    text-transform: uppercase;
}
.lang-switch {
    margin-bottom:.5rem;
}
.langmodal-switch__img,
.lang-switch__img {
    position: absolute;
    top:-.125rem;
    left:0;
    display: inline-block;
    width: 1rem;
    height:1rem;
}
.lang-switch:hover .lang-switch__txt,
.lang-switch:focus .lang-switch__txt,
.langmodal-switch:hover .langmodal-switch__txt,
.langmodal-switch:focus .langmodal-switch__txt {
    text-decoration: underline;
}




/* modal */
.lang-switch__languages{
    margin-left:1.5rem;
    font-size: calc(14rem / 16);
}
.lang-switch__languages a:hover,
.lang-switch__languages a:focus{
    text-decoration: underline;
}