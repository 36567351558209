.page-alert {
    z-index: 10;
    position: relative;
    padding: calc(12rem / 16) calc(20rem / 16);
    font-size: calc(13rem / 16);
    line-height: 1;
    display: none;
}
.page-alert.is-active {
    display: block;
}
.page-alert__close {
    position: absolute;
    right: calc(20rem / 16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(12rem / 16);
}
.page-alert .wysiwyg a:hover,
.page-alert .wysiwyg a:focus,
.page-alert .wysiwyg a {
    color: currentColor;
    text-decoration: underline;
}