.content-box {
    padding: calc(30rem / 16) calc(20rem / 16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(40rem / 16);
        font-size: calc(16rem / 16);
    }

}
.content-box__icon {
    @media screen and (min-width: 768px) {
        max-height: calc(80rem / 16);
        width: auto;
    }

    @media screen and (min-width: 1200px) {
        max-height: unset;
    }
}
.content-box--img .content-box__text {
    @media screen and (min-width: 1200px) {
        padding-left: calc(33rem / 16);
    }
}