.job-teaser__content {
    margin-top: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(25rem / 16);
    }
}
.job-teaser__title {
    font-size: calc(20rem / 16);
}
.job-teaser__img {
    transition: transform 200ms ease-in-out;
}
.job-teaser:hover .job-teaser__img {
    transform: scale(1.02);
}