.rating + .rating {
    margin-top: calc(26rem / 16);
}
.rating__item {
    width: 100%;
    height: calc(8rem / 16);
    border-radius: calc(4rem / 16);
    background: var(--color-sand-20);
}
.rating__item + .rating__item {
    margin-left: calc(4rem / 16);
}
.rating__item.rating__item--active {
    background: var(--color-primary);
}

/* rating small */
.rating--sm {
    font-size: calc(14rem / 16);
}
.rating--sm + .rating--sm {
    margin-top: calc(16rem / 16);
}

.rate-product .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.rate-product .custom-control-inline {
    display: inline-flex;
}

.rate-product .js-rate-product__star {
    position: relative;
    padding-left: 0;
}

.rate-product .js-rate-product-star-visual {
    color: var(--color-petrol);
    font-size: calc(23rem/16);
}

.js-auto-translate-product-review-translation-show,
.js-auto-translate-product-review-original-show {
    color: var(--color-primary);
}

.js-auto-translate-product-review-translation-show:hover,
.js-auto-translate-product-review-original-show:hover {
    color: var(--color-primary);
    text-decoration: underline;
}
