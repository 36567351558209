.metabar {
    font-size: calc(12rem / 16);
    line-height: calc(15/12);
    padding: calc(15rem / 16) 0 calc(9rem / 16);
    position: relative;
    z-index: 12;
}
.metabar__list {
    font-size: calc(10rem / 16);
}
.metabar__list__item {
    position: relative;
}
.metabar__list__item + .metabar__list__item {
    padding-left: calc(22rem / 16);
    margin-left: calc(22rem / 16);
}
.metabar__list__item + .metabar__list__item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-100%);
    width: calc(2rem / 16);
    height: calc(2rem / 16);
    border-radius: 50%;
    background: var(--color-text-default);
}