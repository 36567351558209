@media screen and (max-width: 767px){
    .overlay {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        transform: translateX(-110%);
        background-color: #fff;
        transition: transform 200ms ease-in-out;
        z-index: 12;
        display: flex;
        flex-direction: column;
    }
    .overlay.is-open.no-transition {
        transition: none;
    }
    .overlay.is-open {
        transform: translateX(0);
    }
    .overlay__content {
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 1.25rem;
        flex-grow: 1;
    }
    .overlay__top {
        color: var(--color-primary);
        letter-spacing: calc(.5rem / 16);
        text-transform: uppercase;
        padding:1rem;
    }
    .overlay__top-icon {
        position: relative;
        top: calc(1rem / 16);
        font-size: calc(12rem / 16);
        margin-right: calc(5rem / 16);
    }
    .overlay__bottom-btn {
        text-transform: none;
        display: block;
        width: 100%;
    }
}