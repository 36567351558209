.hero {
    display: flex;
    flex-direction: column;
    color: #fff;
    min-height: 100vh;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}
.hero__highlight {
    color: #fff;
    width: 100%;
    padding: calc(20rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        min-height: 100%;
        min-width: calc(300rem / 16);
        width: 25.7%;
        padding: calc(170rem / 16) calc(40rem / 16) calc(40rem / 16);
        flex-direction: column;
    }
}
.hero__highlight__title {
    @media screen and (min-width: 768px) {
        margin-top: calc(44rem / 16);
        padding: calc(16rem / 16) calc(38rem / 16) calc(13rem / 16);
        border: 1px solid transparent;
        border-radius: 4px;
        transition: border-color 200ms ease-in-out;
    }
}
@media screen and (min-width: 768px) {
    .hero__highlight:hover .hero__highlight__title {
        border-color: currentColor;
    }
}
.hero__highlight__img {
    width: calc(130rem / 16);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(324rem / 16);
    }
}
.hero__highlight:hover .hero__highlight__img {
    @media screen and (min-width: 768px) {
        transform: scale(.97);
    }
}
.hero__content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: calc(16rem / 16);
    letter-spacing: calc(.5rem / 16);
    padding: calc(100rem / 16) 0 calc(30rem / 16);

    @media screen and (min-width: 768px) {
        justify-content: center;
        min-height: 100vh;
        font-size: calc(20rem / 16);
        padding: calc(110rem / 16) calc(40rem / 16);
    }
}
.hero__content__title {
    color: currentColor;

    @media screen and (min-width: 768px) {
        max-width: calc(440rem / 16);
    }
}
.hero__content__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: var(--image-src-xs) !important;

    @media screen and (min-width: 768px) {
            background-image: var(--image-src) !important;
    }

}
.hero__content__bg:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .8;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, #000000 100%);
}
.hero__content__bg--align-center-bottom { background-position: center bottom;}
.hero__content__bg--align-right-bottom { background-position: right bottom;}
.hero__content__bg--align-left-bottom { background-position: left bottom;}
.hero__content__bg--align-left-top { background-position: left top;}
.hero__content__bg--align-right-top { background-position: right top;}
.hero__content__bg--align-center-top { background-position: center top;}

.hero__content__bottom {
    position: absolute;
    bottom: calc(80rem / 16);
    left: 0;
    right: 0;
    font-size: calc(14rem / 16);
    line-height: calc(17/14);
}
.hero__scroll {
    background: none;
    border: 0;
    color: inherit;
}
.hero__scroll__icon {
    font-size: calc(20rem / 16);
}
/* make container small if highlight is visible */
.hero__highlight + .hero__content .hero__content-container {
    max-width: calc(1260rem / 16);
}

/* light */
.hero--light {
    color: var(--color-primary);
}
.hero--light .hero__content__bg:after {
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}

/* small hero image */
.hero-sm {
    position: relative;
    padding-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: 0;
    }
}
@media screen and (max-width: 767px) {
    .hero-sm:before {
        content: '';
        position: absolute;
        background: var(--color-sand-20);
        left: 0;
        bottom: 0;
        top: calc(17rem / 16);
        right: calc(50rem / 16);
    }
    .hero--media-right:before {
        left: calc(50rem / 16);
        right: 0;
    }
}
.hero-sm__image {
    z-index: 1;
    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem / 16);
    }
}
.hero-sm__content-col {
    @media screen and (min-width: 768px) {
        margin-left: -33.33333%;
    }
}
.hero-sm__content-col--sm {
    @media screen and (min-width: 768px) {
        margin-left: -50%;
    }
}
.hero-sm__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: calc(30rem / 16) calc(60rem / 16) calc(10rem / 16) 0;

    @media screen and (min-width: 768px){
        min-height: calc(200rem / 16);
        background: var(--color-sand-20);
        padding: 0 calc(20rem / 16) calc(30rem / 16) 45%;
    }

    @media screen and (min-width: 1200px) {
        min-height: calc(380rem / 16);
    }
}
.hero-sm__content-col--sm .hero-sm__content {
    @media screen and (min-width: 768px) {
        padding-left: 67%;
    }
}
.hero-sm__content-container {
    max-width: calc(406rem / 16);
    width: 100%;
}
.hero-sm__title {
    position: relative;
    z-index: 1;
    font-size: calc(42rem/16);
    max-width: 76%;

    @media screen and (min-width: 768px) {
        font-size: calc(38rem/16);
        margin-top: calc(-14rem / 16);
        margin-bottom: calc(20rem / 16);
        margin-right: -25%;
        max-width: 95%;
    }

    @media screen and (min-width: 1400px) {
        margin-top: calc(-24rem / 16);
        font-size: calc(56rem/16);
    }
}
.hero--media-right .hero-sm__title {
    @media screen and (min-width: 768px) {
        margin-right: 0;
        margin-left: -25%;
    }
}
.hero--media-right .hero-sm__content-col--sm {
    @media screen and (min-width: 768px) {
        margin-left: 8.33333%;
        margin-right: -58.4%;
    }
}
.hero--media-right .hero-sm__content-col--sm .hero-sm__content {
    @media screen and (min-width: 768px) {
        padding-left: calc(20rem / 16);
    }
}
.hero--media-right .hero-sm__content-col--sm .hero-sm__title {
    @media screen and (min-width: 768px) {
        margin-left: -13%;
        max-width: 30%;
    }
}