:root{
    --raffle-nav-height: 40px
}

.raffle__container {
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: var(--header-height-mobile);
    background: var(--color-white);
    z-index: 11;
    top: 0;
    left: 0;
    opacity: 1;
    transform-origin: top left;
    transition-duration: 1s;
    transition-property: transform, opacity;

    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        padding-top: var(--header-height);
    }

    @media (min-width: 1921px) {
        padding-top: var(--header-height-xxl);
    }
}

.raffle__container-wrapper{
    overflow-y: auto;

    flex-grow: 1;
    margin-top: var(--raffle-nav-height);
}

@keyframes fadeOutRaffle {
    1% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.raffle__container--animating > div{
    opacity: 0;
}

.raffle__start-banner{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.raffle__content{
    position: relative;

    display: flex;
    flex-wrap: nowrap;
}
.raffle__content .load-container:before {
    height: 24px;
    width: 24px;
    content: url("/static/img/ortovox/loading.gif");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.raffle__content .loader {
    display: none;
}

.raffle__step{
    flex: 0 0 100%;
    transition-duration: .33s;
    transition-property: transform, opacity;
    opacity: 0;
    transform: translateX(100%);
    padding-top: calc(40rem/16);
}

.raffle__step--active{
    opacity: 1;
    transform: translateX(0)
}

.raffle__step--deactivate{
    opacity: 0;
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    left: 0;
}

.raffle__tabs{
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;

    margin: 0;

    overflow-x: hidden;

    @media screen and (min-width: 768px) {
        overflow-x: initial;
    }
}

.raffle__tabcontainer{
    position: fixed;
    top: var(--header-height-mobile);
    left: 0;
    width: 100%;
    height: var(--raffle-nav-height);

    display: flex;
    flex-wrap: nowrap;

    background: var(--color-white);

    z-index: 1;

    @media (min-width: 768px) {
        top: var(--header-height);
        background: var(--color-warm-grey);
    }

    @media (min-width: 1921px) {
        top: var(--header-height-xxl);
    }
}

.raffle__tabcontainer:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
    height: 40px;
    width: 3em;
    background: var(--color-white);
    transition-duration: .33s;
    transition-property: opacity;

    @media (min-width: 768px) {
        display: none;
    }
}

.raffle__tabcontainer:after{
    content: '';
    position: absolute;
    top: 0;
    right: calc(var(--raffle-nav-height));
    z-index: 2;
    pointer-events: none;
    height: 40px;
    width: 3em;
    background: var(--color-white);
    transition-duration: .33s;
    transition-property: opacity;

    @media (min-width: 768px) {
        display: none;
    }
}
.raffle__tabcontainer--morecontent-left:before,
.raffle__tabcontainer--morecontent-right:after{
    display: none;
}

.raffle__tab{
    flex: 0 0 100%;
    z-index: 1200;

    @media (min-width: 768px) {
        flex: 1;
    }
}
.raffle__tab:last-child{
    border-right: none;
}

.raffle__tab-link{
    display: flex;
    justify-content: center;
    align-items: center;

    height: var(--raffle-nav-height);

    cursor: default;
    white-space: nowrap;

    text-transform: uppercase;

    border-radius: .25rem;
    padding: .9375rem min(10%,2.375rem) .6875rem;
    margin: 0 calc(10rem/16);
    font-size: .875rem;
    line-height: 1.21429;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);

    background: #fff;
    color: var(--color-primary);;
    -webkit-box-shadow: 0 .625rem 3.125rem 0 rgba(0,58,96,.1);
    box-shadow: 0 .625rem 3.125rem 0 rgba(0,58,96,.1);

    &:hover,
    &:focus {
        background: var(--color-primary-light);
        border-color: var(--color-primary-light);
        color: var(--color-primary-contrast);
    }
}

.raffle__tab-link--clickable{
    cursor: pointer;
}

.raffle__tabs-progress{
    position: absolute;
    top: 0;
    left: 0;
    height: var(--raffle-nav-height);
    transition-duration: .33s;
    transition-property: width;
    will-change: width;
}

.raffle__tabs-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: var(--raffle-nav-height);
    height: var(--raffle-nav-height);
    border: 0;
    border-radius: .25rem;

    background: #fff;
    color: var(--color-primary-contrast);
    -webkit-box-shadow: 0 .625rem 3.125rem 0 rgba(0,58,96,.1);
    box-shadow: 0 .625rem 3.125rem 0 rgba(0,58,96,.1);

    opacity: 1;
    visibility: visible;
    transition-duration: .33s;
    transition-property: opacity;
}

.raffle__tabs-btn:hover,
.raffle__tabs-btn:focus {
    background: var(--color-primary-light);
    border-color: var(--color-primary-light);
    color: var(--color-primary-contrast);
}

.raffle__mobile-buttons{
    display: flex;
    flex-wrap: nowrap;
    flex-shrink: 0;
}

.raffle__mobile-btn{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.raffle__error-container {
    display: block;
}

/* step content */
.raffle__step-content {
    padding: calc(25rem / 16) calc(16rem / 16);

    @media (min-width: 992px) {
        padding: calc(100rem / 16) 0;
    }
}
.raffle__step-text {
    @media (min-width: 992px) {
        margin-top: calc(12rem / 16);
    }
}
.raffle__step-content select.form-control {
    text-align: left;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(/static/img/icons/select-arrow.svg);
    background-size: calc(14rem / 16);
    background-repeat: no-repeat;
    background-position: calc(100% - calc(15rem / 16)) 50%;
}
.raffle__step-content .custom-file {
    height: calc(50rem / 16);
}
.raffle__step-content .custom-file-label,
.raffle__step-content .form-control:not(textarea) {
    height: calc(50rem / 16);
    padding: calc(12rem / 16) calc(15rem / 16) calc(4rem / 16);
}
.raffle__step-content .custom-file__name,
.raffle__step-content .form-control {
    padding: calc(12rem / 16) calc(15rem / 16) calc(4rem / 16);
}
.raffle__step-content .form-control {
    padding: calc(20rem / 16) calc(15rem / 16) calc(4rem / 16);
}
.raffle__step-content .custom-file-label:after {
    height: calc(48rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.raffle__step-content .floating-label {
    left: calc(15rem / 16);
}
.raffle__step-content .form-control.has-value ~ .floating-label,
.raffle__step-content .form-control:focus ~ .floating-label,
.raffle__step-content select ~ .floating-label {
    transform: translateY(-90%) scale(.65) translateX(0);
}
.raffle__step-content textarea ~.floating-label {
    top: calc(15rem / 16);
}
.raffle__step-content textarea.form-control.has-value ~ .floating-label,
.raffle__step-content textarea.form-control:focus ~ .floating-label {
    transform: translateY(-45%) scale(.65) translateX(0);
}
.raffle__step-content .btn[type="submit"] {
    margin-top: calc(46rem / 16);
}
.raffle__step-content .custom-checkbox .form-errors {display: block;}

@media (max-width: 767px) {
    .raffle__content .custom-checkbox__image,
    .raffle__content .custom-radio__image {
        max-width: 50%;
    }
}

.raffle__content .has-error .custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-danger);
}

.raffle__content .custom-radio__box__image-container,
.raffle__content .custom-checkbox__box__image-container {
    border: 2px solid var(--color-sand-20);
}

.raffle__content .custom-radio__input:checked ~ .custom-radio__box__image-container,
.raffle__content .custom-checkbox__input:checked ~ .custom-checkbox__box__image-container {
    border-color: var(--color-primary-light);
}

.raffle__content .custom-radio--image .custom-radio__box,
.raffle__content .custom-checkbox--image .custom-checkbox__box {
    margin-left: calc(2rem/16);
    margin-top: calc(2rem/16);
}

.raffle__teaser {
    background-color: var(--color-sand-20);
}

.raffle__container--animating{
    background-color: var(--color-black-60);
}

.raffle__step{
    background-color: var(--color-background-grey)
}

.raffle__tabs-close{
    height: var(--raffle-nav-height);
    width: var(--raffle-nav-height);
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-anthracite-40);
    color: var(--color-anthracite);

    border: 0;
    border-radius: 0;
}

.raffle__tabs-prev{
    border-right: 1px solid #fff;
}
.raffle__tabs-next{
    border-left: 1px solid #fff;
}

.raffle__tabs-btn--remove{
    opacity: 0;
    visibility: hidden;
}

.raffle__content .custom-radio--border label, .custom-checkbox--border label{
    background-color: var(--color-sand-20);
    padding: 10px 15px;
}
.raffle__content .custom-checkbox--image .custom-checkbox__outer-box:after,
.raffle__content .custom-radio--border .custom-radio__outer-box:after {
    border-color: #C0BAB8;
    opacity: 1;
}
.raffle__content .custom-radio__outer-box,
.raffle__content .custom-checkbox__outer-box {
    background: #eee;
}
.raffle__content .custom-checkbox--image .custom-checkbox__outer-box:after,
.raffle__content .custom-radio--image .custom-radio__outer-box:after {
    border-color: #C0BAB8;
    opacity: 0;
}

.raffle__area-title > p{
    margin: 0;
}

.raffle__content .btn.btn-primary{
    text-transform: uppercase;
}
.raffle__tabs-close .icon{
    margin-top: 4px;
}
.raffle__tab-link--progress{
    color: #fff;
    background-color: #003a60;
    border-color: #003a60;
}


.raffle__container {
    transform: translateY(-100%) !important;
}

.raffle__container.raffle__container--loaded {
    transform: translateY(0%) !important;
}

.raffle__step .custom-file.has-value .floating-label {
    transform: translateY(0%) scale(.65) translateX(1.5625rem);
}

.raffle__step .custom-file {
    height: auto;
    position: relative;
    margin-top: 30px;
}

.raffle__mobile-btn.js-raffle__mobile-submit.mai__btn--disabled {
    pointer-events: auto;
}

.modal--raffle-cancel + .modal-backdrop {
    pointer-events: none;
}

.modal--raffle-cancel + .modal-backdrop.show {
    pointer-events: auto;
}
