.navbar-dropdown {
    opacity: 0;
    visibility: hidden;
    transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out;
    position: absolute;
    top: 100%;
    margin-top: calc(15rem / 16);
    right: 0;
    box-shadow: calc(10rem / 16) calc(10rem / 16) calc(30rem / 16) 0 rgba(0,0,0,.1);
    background: #fff;
    color: var(--color-text-default);
    width: calc(405rem / 16);
    line-height: calc(20/16);
    min-height: calc(150rem / 16);
}
.navbar-dropdown__content {
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    padding: calc(32rem / 16) calc(40rem / 16);
}
.navbar-dropdown__content.scrolling-area {
    padding: calc(32rem / 16) calc(40rem / 16);
}
.navbar-dropdown--lg {
    width: 100%;

    @media screen and (min-width: 768px) {
        width: calc(485rem / 16);
    }
}
.main-navbar__right__item.is-open .navbar-dropdown,
.main-navbar__right__item:not(.main-navbar__right__item--disable-hover):hover .navbar-dropdown {
    opacity: 1;
    visibility: visible;
}
.navbar-dropdown__title {
    margin-bottom: calc(16rem / 16);
}
.navbar-dropdown__item {
    border-bottom: calc(1rem / 16) solid var(--color-sand-20);
    padding: calc(10rem / 16) 0;
}
.navbar-dropdown__item:first-child {
    border-top: calc(1rem / 16) solid var(--color-sand-20);
}
.navbar-dropdown__link {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-size: calc(14rem / 16);
    padding: calc(10rem / 16) calc(8rem / 16) calc(7rem / 16);
}
.navbar-dropdown__link__icon {
    position: relative;
    top: calc(-2rem / 16);
    margin-right: calc(8rem / 16);
}
.navbar-dropdown__link:hover {
    color: var(--color-primary);
}