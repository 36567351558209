.grid-content-teaser__content {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(20rem / 16);
    }
}
.grid-content-teaser__title {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.grid-content-teaser__img {
    transition: transform 200ms ease-in-out;
}
.grid-content-teaser:hover .grid-content-teaser__img {
    transform: scale(1.02);
}