.splide-tabs .splide__track--nav {
    z-index: 1;
    padding-top: 38px;
    margin: 0 calc((var(--bs-gutter-x)/2) * -1);
}

.splide-tabs .splide__track--nav .splide__slide {
    position: relative;
}

.splide-tabs .splide__track--nav > .splide__list > .splide__slide {
    border: none;
}

.splide-tabs__tab {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    font-family: var(--font-default);
    color: var(--color-dark-grey);
    text-transform: uppercase;
    border: 1px solid var(--color-dark-grey);
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.splide-tabs__slide--active .splide-tabs__tab:not(.splide-tabs__tab--inactive) {
    background: var(--bs-white);
    border-color: var(--bs-white);
    box-shadow: rgba(0, 58, 96, 0.2) 0 10px 50px 0;
}

.splide-tabs__tab:hover,
.splide-tabs__slide--active .splide-tabs__tab:not(.splide-tabs__tab--inactive) {
    color: var(--color-primary);
}

.splide-tabs__tab--prev,
.splide-tabs__tab--next {
    position: absolute;
    top: 0;
    width: 28px;
}

.splide-tabs__tab--prev {
    left: -38px;
}

.splide-tabs__tab--next {
    position: absolute;
    right: -38px;
}

.splide-tabs__navigation__prev,
.splide-tabs__navigation__next {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.splide-tabs__elements {
    width: 100%;
    background: var(--bs-white);
    box-shadow: rgba(0, 58, 96, 0.2) 0 10px 50px 0;
}
