.customer-backend__tab a, .customer-backend__tab > div{
    display: inline-block;
    color: var(--color-primary);
    border-radius: calc(4rem / 16);
    background-color: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);
    padding: 1rem 2rem;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: calc(14rem / 16);
    line-height: calc(17/14);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    @media screen and (max-width: 767px) {
        padding: .75rem 1rem;
    }
}

.customer-backend__tab--home a{
    position: relative;
    top:1px;
    font-size: calc(16rem / 16);
    line-height: 1.1;
}

.customer-backend__tab.customer-backend__tab--home a, .customer-backend__tab.customer-backend__tab--home div{
    padding: .875rem 2rem;
    @media screen and (max-width: 767px) {
        padding: .625rem 1.25rem;
    }
}

.customer-backend__tab.is-active a,
.customer-backend__tab.is-active>div{
    background: var(--color-primary);
    color: #fff;
}