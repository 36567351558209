.typeahead .react-select__control {
    border-radius: .25rem;
    background: transparent;
    border: calc(1rem / 16) solid var(--color-sand-20);
    padding: calc(5rem / 16) calc(10rem / 16) calc(3rem / 16);
    min-height: calc(32rem / 16);
    font-size: calc(16rem / 16);
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
}
.main-navbar--dark .typeahead .react-select__control {
    border-color: var(--color-primary);
}
.typeahead .react-select__placeholder {
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        color: currentColor;
    }
}
.typeahead .react-select__value-container {
    padding: 0;
    padding-left: calc(12rem / 16);
}
.typeahead .react-select__indicators {
    display: none;
}
.typeahead .react-select__menu {
    margin: 0;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);
    border-radius: calc(4rem / 16);
    padding: calc(5rem / 16) calc(5rem / 16);

    @media screen and (min-width: 768px) {
        min-width: calc(520rem / 16);
        right: 0;
    }
}
.typeahead .react-select__menu-list {
    padding: 0 calc(15rem / 16);
}
.typeahead .react-select__option {
    color: var(--color-text-default);
    font-size: calc(14rem / 16);
    padding: calc(8rem / 16) 0;
}
.typeahead .react-select__option--is-focused,
.typeahead .react-select__option:hover {
    color: var(--color-primary);
    cursor: pointer;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.typeahead .react-select__option:empty {
    display: none;
}
.typeahead .react-select__group-heading  {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    text-transform: none;
    color: var(--color-text-default);
    font-size: calc(14rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-sand-20);
    padding: calc(8rem / 16) 0;
}
.typeahead .react-select__group-link {
    color: var(--color-primary);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
.typeahead .react-select__group-link:hover {
    text-decoration: underline;
}
.typeahead__img {
    padding: calc(6rem / 16);
}
.typeahead__img__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-height: 100%;
    width: auto;
}
.typeahead__group--product .typeahead__title {
    font-family: var(--font-default-compact);
    font-weight: var(--font-default-compact-weight);
    font-style: var(--font-default-compact-style);
    text-transform: uppercase;
    color: var(--color-primary);
    font-size: calc(18rem/16);
    line-height: 1;
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}

.react-select__menu-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 calc(8rem / 16) rgba(0, 0, 0, 0.2);
    background-color: transparent;
    transition: all 120ms ease;
}

.react-select__menu-list::-webkit-scrollbar {
    width: calc(2rem / 16);
    height: calc(2rem / 16);
    background: var(--color-sand-20);
    transition: all 120ms ease;
}

.react-select__menu-list::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    transition: all 120ms ease;
}