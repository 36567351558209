/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-underline {text-decoration: underline;}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-color-current {color: currentColor;}
.text-primary-hover:hover, .text-primary-hover:focus {color: var(--color-primary);}
.text-color-sand-20 {color: var(--color-sand-20);}

/* Background Color Helper */
.bg-primary, a.bg-primary:hover {color: #fff;}
.bg-white {color: var(--color-primary);}
.bg-sand-10 {background: var(--color-sand-10);}
.bg-sand-20 {background: var(--color-sand-20);}
.bg-info {color: var(--color-text-default);}


hr.text-color-sand-20 {
    opacity: 1;
}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}
.clickable {cursor: pointer;}

.sr-only, .visually-hidden {
    color: black !important;
    background: white !important;
}

/* sr only toc fix */
.sr-only{
    position:absolute;
    width:1px;
    height:1px;
    padding:0;
    overflow:hidden;
    clip:rect(0,0,0,0);
    white-space:nowrap;
    clip-path:inset(50%);
    border:0
}
.sr-only-focusable:not(:focus) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

/* data grids */
.data-grid {
    display: grid;
    grid-gap: 0 calc(5rem / 16);
    grid-template-columns: fit-content(35%) auto;

    @media screen and (min-width: 768px) {
        grid-gap: 0 calc(24rem / 16);
        grid-template-columns: fit-content(30%) auto;
    }
}

.ratio--max-vh-100 { max-height: 100vh; }
/*.ratio--max-vh-100:before {
    padding-top: min(var(--aspect-ratio), 100vh);
}*/
.ratio-1x1.ratio--max-vh-100:before {
    padding-top: min(100%, 100vh); /* fallback for edge 18 ... */
}

.img-fluid-flex {
    max-height: 100%;
    width: auto;
}

/* some bootstrao 4 stuff */
.text-right {
    text-align: right;
}