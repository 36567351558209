.scrolling-map__list {
    padding: calc(20rem / 16);
    height: 100%;

    @media screen and (min-width: 768px) {
        padding: 0 calc(40rem / 16);
    }
}
.scrolling-map__map {
    height: 50vh;

    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(103rem / 16))
    }
}