.number-spinner{
    display: flex;
    height: 2.5rem;
    width: 7.5rem;
    justify-content: center;
    background-color: var(--color-sand-10);
}

.btn.number-spinner__btn{
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary);
    background-color: var(--color-sand-10);
    font-size: .625rem;
}

.number-spinner__input{
    border: none;
    color: var(--color-primary);
    width: 2.5rem;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
    font-size: calc(14rem / 16);
    height: 2.5rem;
    background-color: var(--color-sand-10);
}

.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
}