.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}

.select__input-wrapper {
    position: relative;
}
.select__input-wrapper:before {
    font-family: "iconfont";
    content: var(--icon-dropdown);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(3rem / 16);
    top: 50%;
    right: calc(16rem / 16);
    transform: translateY(-50%);
    z-index: 1;
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        right: calc(20rem / 16);
    }
}