.shipping-methods {
    margin:0 0 4rem 0;
}
.payment-methods {
    margin:4rem 0 0 0;
}
.payment-methods__hl.payment-methods__hl,
.shipping-methods__hl.shipping-methods__hl {
    font-size: 1.25rem;
}
.payment-methods__item,
.shipping-methods__item {
    display: block;
    position: relative;
    font-size: .875rem;
    margin-bottom:1rem;
}
.payment-methods__img,
.shipping-methods__img {
    position: relative;
    text-align: center;
    padding:1.125rem;
    margin-bottom:.75rem;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 10px 50px 0 rgba(0,58,96,0.1);
    @media screen and (min-width: 768px) {
        padding:2rem;
    }
}
.payment-methods__img__img,
.shipping-methods__img__img {
    display: inline-block;
    width: auto;
    height:1.625rem;
    @media screen and (min-width: 768px) {
        height:1.875rem;
    }
}
.payment-methods__select-icon,
.shipping-methods__select-icon {
    display: none;
    position: absolute;
    right:.5rem;
    bottom:.5rem;
}
.payment-methods__item.payment-methods__item--selected .payment-methods__select-icon,
.shipping-methods__item.shipping-methods__item--selected .shipping-methods__select-icon {
    display: block;
}


.payment-collapse-content {
    padding:1rem;
    background: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);
    position: relative;
}

/* heidelpay */
.unzerUI.form .field.error .input, .unzerUI.form .field.error label, .unzerUI.form .fields.error .field .input, .unzerUI.form .fields.error .field label {
    color: var(--color-danger);
}
.unzerUI.form.form .disabled.field, .unzerUI.form.form .disabled.fields .field, .unzerUI.form.form .field :disabled {
    opacity: .65;
}
#birthdate-calendar-button.btn {
    padding:.625rem 1rem;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);
}
.payment-collapse-content .btn-success,
.unzerUI.btn-success {
    display: inline-block;
    width: auto;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    display: flex;
    margin-left: auto;
}