@media screen and (max-width: 767px) {
    .product-colors {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 10;
        visibility: hidden;
    }
    .product-colors:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-default);
        opacity: 0.8;
    }
    .product-colors.is-open {
        opacity: 1;
        visibility: visible;
    }

    .product-colors__content {
        background: #fff;
        z-index: 2;
        padding: calc(16rem / 16) 0 calc(32rem / 16);
        transform: translateY(100%);
        transition: transform 200ms ease-in-out;
    }
    .product-colors.is-open .product-colors__content {
        transform: translateY(0);
    }

    .product-colors__close {
        background: none;
        border: none;
        color: var(--color-sand);
        transition: transform 200ms ease-in-out;
        position: absolute;
        z-index: 2;
        right: calc(14rem / 16);
        top: calc(18rem / 16);
        font-size: calc(14rem / 16);
    }
}
.product-colors__title {
    border-bottom: calc(1rem / 16) solid var(--color-sand-20);
    padding-bottom: calc(5rem / 16);
    padding-right: calc(20rem / 16);
    font-size: calc(16rem / 16);
    margin: 0 calc(20rem / 16) calc(16rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: 0;
        margin: 0;
    }
}
.product-colors__item {
    padding-top: calc(10rem / 16);
}
.product-colors__item__top {
    width: calc(160rem / 16);
    height: calc(160rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        width: calc(80rem / 16);
        height: calc(80rem / 16);
    }
}
.product-colors__item:after {
    content: '';
    position: absolute;
    top: calc(-1rem / 16);
    left: 0;
    right: 0;
    height: calc(1rem / 16);
    background: var(--color-text-default);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.product-colors__item--active:after {
    opacity: 1;
}
.product-colors__item__img {
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: transform 200ms ease-in-out;
}

.product-price {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}
.product-price__currency {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}

.product-detail__title {
    font-size: calc(30rem/16);
    line-height: 1;
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
        letter-spacing: calc(1rem / 16);
    }
}

.product-highlight-badge-elements img {
    min-width: calc(35rem/16);
}

.rating-color{
    color: var(--color-text-grey);
}

