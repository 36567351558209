.product-teaser {
    font-size: calc(14rem / 16);
}
.product-teaser__content {
    padding-top: calc(10rem / 16);
    transition: opacity 250ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding-top: calc(20rem / 16);
        min-height:calc(95rem / 16); 
    }
}
.product-teaser__title {
    font-size: calc(14rem / 16);
    line-height: calc(18/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.product-teaser__top {
    background: var(--color-sand-20);
    padding: calc(20rem / 16);
}
.product-teaser__top__badges {
    display: block;
    position: absolute;
    top: calc(10rem / 16);
    left: calc(10rem / 16);
    right: calc(10rem / 16);
    z-index: 1;

    @media screen and (min-width: 1200px) {
        top: calc(20rem / 16);
        left: calc(20rem / 16);
        right: calc(20rem / 16);
    }
}

.product-teaser__top__badges .badge {
    float: right;
    margin-left: 10px!important;
    margin-bottom: 10px;
    display: block;
}
.product-teaser__top__bottom {
    position: absolute;
    opacity: 0;
    bottom: calc(10rem / 16);
    left: calc(10rem / 16);
    right: calc(10rem / 16);
    z-index: 1;
    transition: opacity 200ms ease-in-out;

    @media screen and (min-width: 1200px) {
        bottom: calc(16rem / 16);
        left: calc(20rem / 16);
        right: calc(20rem / 16);
    }
}
.product-teaser:hover .product-teaser__top__bottom{
    opacity: 1;
}

.product-teaser__top__bottom {
    @media (hover: none) {
        display: none;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.product-teaser__top__img {
    max-height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        max-height: 85%;
    }
}
.product-teaser:hover .product-teaser__top__img {
    transform: translateY(-50%) translateX(-50%) scale(1.03);
}
.product-teaser__price {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.product-teaser__price__currency {
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
}
.product-teaser__color {
    position: absolute;
    bottom: calc(10rem / 16);
    left: calc(10rem / 16);
    right: calc(10rem / 16);
    display: none;

    @media (hover: none) {
        display: block;
    }
    @media screen and (max-width: 767px) {
        display: block;
    }
}

/* hover content */
.product-teaser__content--hover {
    @media screen and (max-width: 767px) {
        display: none;
    }
    @media (hover: none) {
        display: none;
    }

    position: absolute;
    top: calc(5rem / 16);
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    pointer-events: none;
    font-size: calc(12rem / 16);
}
@media screen and (min-width: 768px) and (hover: hover) {
    .product-teaser--hover:hover .product-teaser__content--hover {
        opacity: 1;
        pointer-events: auto;
    }
    .product-teaser--hover:hover .product-teaser__content {
        opacity: 0;
    }
}
.product-teaser__variant {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    padding: calc(6rem / 16);
    background: var(--color-sand-20);
}
.product-teaser__variant__img  {
    max-height: 100%;
    width: auto;
}