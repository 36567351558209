.modal-content {
    border: 0;
    box-shadow: 0 10px 150px 0 rgba(0,58,96,0.35);
}
.modal-header {
    padding:1rem 2rem .75rem 2rem;
}
.modal-body {
    padding: 2rem;
}
.modal-body__content + .modal-body__content {
    margin-top: calc(48rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(64rem / 16);
    }
}

.modal-title--lg  {
    font-size: calc(24rem / 16);
    line-height: calc(28/24);
    margin-bottom: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(50rem / 16);
    }
}
.modal-close-lg {
    position: absolute;
    right: calc(30rem / 16);
    top: calc(30rem / 16);
    font-size: calc(20rem / 16);
    color: var(--color-text-default);
    z-index: 1;

    @media screen and (min-width: 768px) {
        right: calc(40rem / 16);
        top: calc(40rem / 16);
    }
}

/* lang modal */
.modal--lang .modal-body {
    padding: calc(80rem / 16) calc(40rem / 16);
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) calc(30rem / 16);
        max-width: calc(866rem / 16);
    }
}