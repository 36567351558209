.wishlist__btn--plain {
    color: var(--color-sand);
    transition: color 200ms ease-in-out;
    font-size: calc(20rem / 16);
    line-height: 0;
}
.wishlist__btn--plain.is-active {
    color: var(--color-primary);
}

.is-active .wishlist__btn__icon:before {
    content: var(--icon-heart-filled);
}
/* wishlist item */
.wishlist-item {
    padding: calc(20rem / 16);
    border-radius: calc(4rem / 16);
    background-color: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
}
.wishlist-item__title {
    padding-right: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: 0;
    }
}
.wishlist-item__remove {
    font-size: calc(20rem / 16);
    position: absolute;
    top: calc(18rem / 16);
    right: calc(18rem / 16);
}
.wishlist-item__img-container {
    width: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        width: 100%;
    }
}
.wishlist-item__img {
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16);
    }
}
.wishlist-item__img__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-height: 100%;
    width: auto;
}