.summary__item {
    position: relative;
}
.summary__item + .summary__item {
    margin-top: calc(10rem / 16);
    padding-top: calc(10rem / 16);
}
.summary__item + .summary__item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(1rem / 16);
    background: var(--color-sand-20);
}
.summary__item + .summary__item--total {
    padding-top: calc(22rem / 16);
}