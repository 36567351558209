:root {
    --mega-nav-level-0-item-spacing: calc(8rem / 16);
}

.mega-nav__item-content--level-0:after,
.mega-nav__item-content--level-0,
.mega-nav__list--level-0 {
    @media screen and (min-width: 1200px) {
        --mega-nav-level-0-item-spacing: calc(16rem / 16);
    }
}

.mega-nav__item-content--level-0 {
    @media (hover: none) {
        display: flex;
    }
    @media screen and (max-width: 767px) {
        display: flex;
    }
}

.mega-nav__item-content--level-0 {
    @media screen and (min-width: 768px) {
        flex-direction: column;
        flex: auto;
        position: relative;
        padding: calc(39rem / 16) var(--mega-nav-level-0-item-spacing);
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1200px){
        font-size: calc(16rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .mega-nav--level-0 {
        display: flex;
        flex: auto;
        align-items: center;
        height: 100%;
    }
    .mega-nav__list--level-0 {
        display: flex;
        flex-direction: row;
        margin-left: calc(var(--mega-nav-level-0-item-spacing) * -1);
        margin-right: calc(var(--mega-nav-level-0-item-spacing) * -1);
    }

    .mega-nav__item--level-0 {
        display: flex;
        flex-direction: column;
        flex: auto;
        position: static;
    }

    .mega-nav__item.is-active > .mega-nav__item-content--level-0 {
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }

    .mega-nav__item-content--level-0:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: var(--mega-nav-level-0-item-spacing);
        right: var(--mega-nav-level-0-item-spacing);
        height: calc(2rem / 16);
        background: var(--color-primary);
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
    .mega-nav__item--level-0:hover .mega-nav__item-content--level-0:after,
    .mega-nav__item-content--level-0:focus:after,
    .mega-nav__item-content--level-0:active:after {
        opacity: 1;
    }
}
