@media screen and (min-width: 768px) {
    .mega-nav--level-1 {
        background-color: #fff;
        color: var(--color-default);
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        padding: calc(70rem / 16) calc(10rem / 16);
        box-shadow: 0 calc(100rem / 16) calc(100rem / 16) 0 rgba(0,0,0,0.1);
        font-size: calc(14rem / 16);
        line-height: calc(17/14);
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        transition-delay: 150ms;
    }
    .mega-nav__list--level-1 {
        display: flex;
        flex-wrap: wrap;
    }
    .mega-nav__item:hover .mega-nav--level-1,
    .mega-nav__item.is-open .mega-nav--level-1 {
        opacity: 1;
        visibility: visible;
    }

    .mega-nav__title--level-1 {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        margin-bottom: calc(28rem / 16);
    }
    .mega-nav__item-content--level-1 {
        display: block;
        padding: calc(5rem / 16) 0;
    }
    .mega-nav__item--level-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 0 0 33%;
        max-width: calc(192rem / 16);
        margin: calc(4rem / 16) calc(20rem / 16) calc(3rem / 16) 0;
    }
    .mega-nav__list--sm {
        flex-direction: column;
    }
    .mega-nav__list--sm .mega-nav__item--level-1 {
        flex: 0 0 100%;
    }
    .mega-nav__item-content--level-1:hover,
    .mega-nav__item-content--level-1:focus,
    .mega-nav__item-content--level-1:active {
        color: var(--color-primary);
    }

    .mega-nav__content {
        max-width: calc(855rem / 16);
        width: 100%;
        margin: 0 auto;
    }
}
.mega-nav__link__icon {
    font-size: calc(9rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(2rem / 16);
    }
}