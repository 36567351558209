.img-slide--bg {
    position: relative;
    min-height: calc(250rem / 16);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 768px) {
        min-height: calc(460rem / 16);
    }
}
.img-slide__bg {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.img-slide--bg--overlay:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-primary);
    opacity: .3;
}
.img-slide__content {
    position: relative;
    z-index: 1;
    padding: calc(20rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(40rem / 16) 0;
    }
}

.img-slide--bg--offset .img-slide__bg {
    height: 95%;
}
.img-slide--bg--offset .img-slide__content {
    padding-bottom: calc(60rem / 16);
    margin-bottom: calc(-30rem / 16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(80rem / 16);
        margin-bottom: calc(-80rem / 16);
    }
}