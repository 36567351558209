.cookie-overlay {
    z-index: 100;
    background-color: rgba(255,255,255,.66667);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cookie-overlay__text {
    z-index:1000;
    text-align: center;

    margin-bottom: calc(20rem / 16);
    padding-right: calc(20rem / 16);
    padding-left: calc(20rem / 16);
}

.js-cookie-overlay__coi-banner-highlighting--cookie_cat_necessary .coi-consent-banner__category-container--cookie_cat_necessary,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_functional .coi-consent-banner__category-container--cookie_cat_functional,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_statistic .coi-consent-banner__category-container--cookie_cat_statistic,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_marketing .coi-consent-banner__category-container--cookie_cat_marketing,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_unclassified .coi-consent-banner__category-container--cookie_cat_unclassified {
    position: relative;
}

.js-cookie-overlay__coi-banner-highlighting--cookie_cat_necessary .coi-consent-banner__category-container--cookie_cat_necessary::before,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_functional .coi-consent-banner__category-container--cookie_cat_functional::before,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_statistic .coi-consent-banner__category-container--cookie_cat_statistic::before,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_marketing .coi-consent-banner__category-container--cookie_cat_marketing::before,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_unclassified .coi-consent-banner__category-container--cookie_cat_unclassified::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -40px;
    right: -40px;
    bottom: -10px;
    z-index: 1;
    background: rgba(255,255,0,.33);
    pointer-events: none;
}

.js-cookie-overlay__coi-banner-highlighting--cookie_cat_necessary .coi-consent-banner__category-container--cookie_cat_necessary .coi-consent-banner__name-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_necessary .coi-consent-banner__category-container--cookie_cat_necessary .coi-consent-banner__description-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_functional .coi-consent-banner__category-container--cookie_cat_functional .coi-consent-banner__name-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_functional .coi-consent-banner__category-container--cookie_cat_functional .coi-consent-banner__description-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_statistic .coi-consent-banner__category-container--cookie_cat_statistic .coi-consent-banner__name-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_statistic .coi-consent-banner__category-container--cookie_cat_statistic .coi-consent-banner__description-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_marketing .coi-consent-banner__category-container--cookie_cat_marketing .coi-consent-banner__name-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_marketing .coi-consent-banner__category-container--cookie_cat_marketing .coi-consent-banner__description-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_unclassified .coi-consent-banner__category-container--cookie_cat_unclassified .coi-consent-banner__name-container,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_unclassified .coi-consent-banner__category-container--cookie_cat_unclassified .coi-consent-banner__description-container {
    position: relative;
    z-index: 1;
}


/* new cookie template */
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_necessary .coi-banner-consent-field #switch-cookie_cat_necessary,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_functional .coi-banner-consent-field #switch-cookie_cat_functional,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_statistic .coi-banner-consent-field #switch-cookie_cat_statistic,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_marketing .coi-banner-consent-field #switch-cookie_cat_marketing,
.js-cookie-overlay__coi-banner-highlighting--cookie_cat_unclassified .coi-banner-consent-field #switch-cookie_cat_unclassified {
    background: rgba(255,255,0,.33);
    margin-top: -20px;
    padding-top: 20px;
    margin-bottom: -20px;
    padding-bottom: 20px;
}