.background-teaser {
    background-size: cover;
    background-repeat: no-repeat;
    padding: calc(10rem / 16);
    min-height: calc(180rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(40rem / 16);
    }
}
.background-teaser__sub-title,
.background-teaser__text,
.background-teaser__title {
    color: currentColor;
}
.background-teaser__title {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(26rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(40rem/16);
    }
}
.background-teaser__sub-title {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.background-teaser__content {
    padding: calc(14rem / 16) calc(10rem / 16);
    font-size: calc(12rem / 16);
    background: var(--color-primary-transparent);
    color: #fff;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(24rem / 16) calc(20rem / 16);
        font-size: calc(14rem / 16);
    }
}
.background-teaser__link-arrow {
    transform: rotate(180deg);
    margin-left: calc(4rem / 16);
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(8rem / 16);
    }
}

/* sizes */
.background-teaser--lg .background-teaser__title {
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(42rem/16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(56rem/16);
    }
}