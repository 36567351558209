.bubbles__container {
    margin-bottom: 10rem;
    position: relative;
    padding-top: 10rem;
}

.bubble-main {
    border-radius: 50%;
    box-shadow: 0 1rem 3rem 0 rgba(0, 58, 96, .3);
    padding: 4rem;
    background-size: cover;
    max-width: 398px;
}

.bubble__spacer {
    @media (min-width: 1200px) {
        margin-bottom: 11rem;
    }
}

.bubbles__count-5 .bubble__spacer {
    @media (min-width: 1200px) {
        margin-bottom: 8rem;
    }
}

.bubble-main__container {
    min-height: 200px;
    aspect-ratio: 1/1;
}

.bubble-main__container-text {
    max-height: calc(24px * 8);
    max-width: 270px;
    overflow: auto;

    /* Quickfix for Firefox var(--font-default) umlaut rendering  */
    margin-top: -20px;
    padding-top: 20px;

    @media screen and (min-width: 1400px) {
        max-width: 370px;
    }
}

.bubble-left {
    @media (min-width: 1200px) {
        margin-right: 50px;
    }
}

.bubbles__count-4 .bubble-left {
    @media (min-width: 1200px) {
        margin-right: 150px;
    }
}

.bubble-right {
    @media screen and (max-width: 1199px) {
        align-items: center;
    }

    @media (min-width: 1200px) {
        margin-left: 50px;
    }
}

.bubbles__count-4 .bubble-right, .bubbles__count-3 .bubble-right {
    @media (min-width: 1200px) {
        margin-left: 150px;
    }
}

.bubble-item-empty {
    @media (min-width: 1200px) {
        height: 112px;
    }
}

.bubble-item__text-container {
    width: 100%;

    @media (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        justify-content: end;
        min-width: 180px;
    }
}

.bubbles__count-5 .bubble-item__wrapper-top-center {
    @media (min-width: 1200px) {
        top: 100px;
        position: absolute;
        left: calc(50% - 55px);
    }
}

.bubbles__count-4 .bubble-item-6-bubbles-6::after {
    @media (min-width: 1200px) {
        transform: rotate(36deg);
        top: 168px;
        right: -259px;
    }
}

.bubbles__count-4 .bubble-item-6-bubbles-4::after {
    @media (min-width: 1200px) {
        transform: rotate(-36deg);
        top: -30px;
    }
}

.bubbles__count-4 .bubble-item-6-bubbles-1::after {
    @media (min-width: 1200px) {
        left: -259px;
        transform: rotate(-36deg);
        top: 168px;
    }
}

.bubbles__count-3 .bubble-item-6-bubbles-1::after {
    @media (min-width: 1200px) {
        left: -214px;
        transform: rotate(-47deg);
        top: 178px;
    }
}

.bubbles__count-3 .bubble-item-6-bubbles-3::after {
    @media (min-width: 1200px) {
        transform: rotate(47deg);
        left: -276px;
    }
}


.bubbles__count-4 .bubble-item-6-bubbles-3::after {
    @media (min-width: 1200px) {
        transform: rotate(36deg);
        top: -30px;
    }
}

.bubble-left .bubble-item__text-container {
    right: 100%;
}

.bubble-item {
    flex-direction: column;
    text-align: center;

    @media (min-width: 1200px) {
        text-align: left;
    }
}

.bubble-right .bubble-item .bubble-item__label {
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: row;
    }
}

.bubble-left .bubble-item .bubble-item__label {
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.bubble-left .bubble-item .bubble-item__text-container {
    @media (min-width: 1200px) {
        text-align: right;
    }
}

.bubble-item__img-container, .bubble-item__text-container {
    z-index: 1;
}

.bubble-left .bubble-item__img-container {
    @media (min-width: 1200px) {
        margin-left: 1rem;
    }
}

.bubble-right .bubble-item__img-container {
    @media (min-width: 1200px) {
        margin-right: 1rem;
    }
}

.bubble-item-left-margin {
    @media screen and (min-width: 1200px) {
        margin-right: -140px;
    }
}

.bubble-item-right-margin {
    @media screen and (min-width: 1200px) {
        margin-left: -140px;
    }
}

.bubble-left .bubble-item__text-container-text {
    right: 0;
}

.bubble-item__text-container-text {
    overflow: auto;
    left: 0;
    display: block;
    transition: 200ms ease;
    opacity: 0;
    height: 0;
    background: #FFFFFF;
    z-index: 1;
    border-radius: 10px;

    @media screen and (min-width: 1200px) {
        position: absolute;
    }
}


.bubbles__count-5 .bubble-left, .bubbles__count-5 .bubble-right {
    @media screen and (min-width: 1200px) {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

.bubbles__count-5 .bubble-item-left-margin {
    @media screen and (min-width: 1200px) {
        margin-right: -90px;
    }
}

.bubbles__count-5 .bubble-item-right-margin {
    @media screen and (min-width: 1200px) {
        margin-left: -90px;
    }
}

.bubbles__count-5 .bubble-item-6-bubbles-6 {
    @media screen and (min-width: 1200px) {
        margin-top: 9rem;
        margin-right: 3px;
    }
}

.bubbles__count-5 .bubble-item-6-bubbles-1 {
    @media screen and (min-width: 1200px) {
        margin-left: 3px;
        margin-top: 9rem;
    }
}

.bubbles__count-5 .bubble-item-6-bubbles-1::after {
    @media screen and (min-width: 1200px) {
        left: -246px;
        transform: rotate(-17deg);
        top: 105px;
    }
}

.bubbles__count-5 .bubble-item-6-bubbles-6::after {
    @media screen and (min-width: 1200px) {
        transform: rotate(17deg);
        top: 105px;
        right: -246px;
    }
}

.bubbles__count-5 .bubble-item-6-bubbles-4::after {
    @media screen and (min-width: 1200px) {
        transform: rotate(-51deg);
        right: -269px;
    }
}

.bubbles__count-5 .bubble-item-6-bubbles-3::after {
    @media screen and (min-width: 1200px) {
        transform: rotate(51deg);
        left: -269px;
    }
}


.bubbles__count-5 .bubble-item__wrapper-top-center .bubble-item__text-container {
    @media screen and (min-width: 1200px) {
        position: absolute;
        top: 0;
        left: calc(112px + 1rem);
        width: 100%;
    }
}

.bubble-item__text-container-icon {
    @media screen and (max-width: 1199px) {
        margin-bottom: .5rem;
    }
}


.bubble-item__image {
    border-radius: 50%;
    box-shadow: 0 .625rem 3.125rem 0 rgba(0, 58, 96, .1);
    height: 112px;
    width: 112px;
    z-index: 1;
    position: relative;
}

.bubble-main__wrapper {
    z-index: 1;
}

/* line after bubble in mobile*/
.bubble-item__wrapper-line::after {
    @media screen and (max-width: 1199px) {
        content: '';
        border: 1px dashed var(--color-primary);
        height: 60px;
        position: absolute;
        right: 50%;
    }
}

.bubble-item__wrapper-line-margin::after {
    @media screen and (max-width: 1199px) {
        bottom: -4.75rem;
    }
}


.bubble-main__wrapper, .bubble-item__wrapper {
    position: relative;
}

.bubble-item__wrapper {
    @media screen and (min-width: 1200px) {
        width: 100%;
    }
}

.bubble-main::after {
    @media screen and (max-width: 1199px) {
        content: '';
        border: 1px dashed var(--color-primary);
        bottom: -60px;
        height: 60px;
        position: absolute;
        right: 50%;
    }
}

/* lines to the main bubble desktop*/
.bubble-item-6-bubbles-1::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        border-top: 1px dashed var(--color-primary);
        width: 312px;
        left: -159px;
        transform: rotate(-57deg);
        top: 151px;
    }
}

.bubble-item-6-bubbles-2::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        top: 56px;
        right: 100%;
        border-top: 1px dashed var(--color-primary);
        width: 281px;
    }
}


.bubble-item-6-bubbles-3::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 310px;
        border-top: 1px dashed var(--color-primary);
        transform: rotate(57deg);
        left: -243px;
        top: -89px;
    }
}

.bubble-item-6-bubbles-4::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        transform: rotate(-57deg);
        right: -243px;
        top: -89px;
        width: 310px;
        border-top: 1px dashed var(--color-primary);
    }
}

.bubble-item-6-bubbles-5::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        top: 56px;
        left: 100%;
        border-top: 1px dashed var(--color-primary);
        width: 261px;
    }
}


.bubble-item-6-bubbles-6::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        border-top: 1px dashed var(--color-primary);
        width: 312px;
        transform: rotate(57deg);
        top: 162px;
        right: -165px;
    }
}

.bubble-item__wrapper-top-center .bubble-item-6-bubbles-2::after {
    @media screen and (min-width: 1200px) {
        content: "";
        position: absolute;
        z-index: 0;
        bottom: 0;
        border-right: 1px dashed var(--color-primary);
        left: calc(112px / 2);
        height: 231px;
        top: 50px;
        width: 0;
    }
}

.bubble-item__wrapper-top-center .bubble-item {
    @media screen and (min-width: 1200px) {
        max-width: 550px;
    }
}

.bubble-item__text-container-icon-close {
    display: none;
}

.bubble-item__text-container-icon-close, .bubble-item__text-container-icon {
    font-size: 1.5rem;
}

/* toggles mobile bubble text */
.bubble__text-mobile-toggle {
    position: fixed;
    z-index: -1;
    top: 0;
    left: -9999px;
}

.bubble__text-mobile-toggle:checked ~ label .bubble-item__text-container .bubble-item__text-container-icon {
    @media screen and (max-width: 1199px) {
        display: none;
    }
}

.bubble__text-mobile-toggle:checked ~ label .bubble-item__text-container .bubble-item__text-container-icon-close {
    @media screen and (max-width: 1199px) {
        display: block;
    }
}

.bubble__text-mobile-toggle:checked ~ label .bubble-item__text-container .bubble-item__text-container-text {
    @media screen and (max-width: 1199px) {
        opacity: 1;
        height: auto;
    }
}

.bubble__text-mobile-toggle:checked ~ label .bubble-item__text-container .bubble-item__text-container-icon {
    @media screen and (max-width: 1199px) {
        margin-bottom: 0;
    }
}

/* toggles desktop bubble text */
.bubble-item:hover .bubble-item__text-container-icon {
    @media screen and (min-width: 1200px) {
        visibility: hidden;
    }
}

.bubble-item:hover .bubble-item__text-container-text {
    @media screen and (min-width: 1200px) {
        display: block;
        opacity: 1;
        height: auto;
    }
}

.bubble-item__text-container-headline, .bubble-main__container-headline {
    font-size: 27px;

    @media screen and (min-width: 576px) {
        font-size: 32px;
    }
}

.bubble-main__container-headline {

    @media screen and (max-width: 576px) {
        margin-bottom: 0.5rem;
    }
}

.bubble-item__text-container-text, .bubble-main__container-text {
    font-size: 16px;
}

.bubble-main__container-text p {
    margin-bottom: 0;
}
