.location-teaser {
    position: relative;
    background: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
    padding: calc(20rem / 16) calc(15rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16);
    }
}
.location-teaser + .location-teaser {
    margin-top: calc(20rem / 16);
}
.location-teaser:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(6rem / 16);
    background: var(--color-primary);
    transform: scaleY(0);
    transition: transform 200ms ease-in-out;
    transform-origin: top;
}
.location-teaser.is-active:before {
    transform: scaleY(1);
}
.location-teaser__title {
    font-size: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem / 16);
    }
}
.location-teaser__link {
    text-align: left;
    font-size: calc(14rem / 16);
}
.location-teaser__collapse-icon {
    font-size: calc(12rem / 16);
    vertical-align: -.1em;
    transform: rotate(90deg);
    margin-left: calc(5rem / 16);
    transition: transform 200ms ease-in-out;
}
.collapsed .location-teaser__collapse-icon {
    transform: rotate(-90deg);
}

.collapsed .location-teaser__collapse-more {
    display: inline-block;
}
.location-teaser__collapse-more,
.collapsed .location-teaser__collapse-less {
    display: none;
}