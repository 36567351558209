html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
    background: #fff;
}
body {
    position: relative;
    background: #fff;
    overflow-x: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
    .container.container--no-padding-xs {
        padding: 0;
    }
    .container .container--fill-xs {
        margin-left: calc(-20rem / 16);
        margin-right: calc(-20rem / 16);
    }
}
.container.container {
    max-width: calc(1720rem/16);
    padding: 0 calc(20rem / 16);
    width: 100%;

    @media screen and (min-width: 1400px) {
        max-width: calc(1740rem/16);
        padding: 0 calc(30rem / 16);
    }
}
.container-md {
    max-width: calc(1397rem / 16);
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}

.container-xxl {
    max-width: calc(1680rem / 16);
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}

.container.container-md {
    max-width: calc(1437rem / 16);

    @media screen and (min-width: 1400px) {
        max-width: calc(1457rem/16);
    }
}
.container-narrow {
    max-width: calc(1114rem / 16);
    margin-left: auto;
    margin-right: auto;
}
.container.container-narrow {
    max-width: calc(1154rem / 16);

    @media screen and (min-width: 1400px) {
        max-width: calc(1174rem/16);
    }
}
.container-xs {
    max-width: calc(830rem / 16);
    margin-left: auto;
    margin-right: auto;
}