.pimcore_area_store-locator iframe {
    min-height: 400px;

    @media screen and (min-height: 700px) {
        min-height: 450px;
    }
    @media screen and (min-height: 800px) {
        min-height: 550px;
    }
    @media screen and (min-height: 900px) {
        min-height: 650px;
    }
    @media screen and (min-height: 1000px) {
        min-height: 750px;
    }
    @media screen and (min-height: 1200px) {
        min-height: 900px;
    }
}