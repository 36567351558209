.tab-content > .tab-pane {
    display: none;
}
.tab-content > .tab-pane.active {
    display: block;
}


/* radio style */
.nav-tabs--radio-style {
    border:0;
    margin: 0 0 1rem 0;
    padding:0;
    display: inline-block;
}
.nav-tabs--radio-style .nav-item {
    display: inline-block;
}
.nav-tabs.nav-tabs--radio-style .nav-item .nav-link {
    background: transparent;
    color:inherit;
}
.nav-tabs--radio-style .nav-item.show .nav-link, .nav-tabs--radio-style .nav-link, .nav-tabs--radio-style .nav-link.active {
    display: inline-block;
    position: relative;
    color: var(--color-text-default);
    border:0;
    padding:.25rem .25rem .25rem 1.5rem;
    font-size: .875rem;
    margin-right:1.5rem;
}
.nav-tabs--radio-style .nav-link:focus, .nav-tabs--radio-style .nav-link:hover {
    border:0;
}

.nav-tabs--radio-style .nav-link:before{
    content:'';
    position: absolute;
    left:0;
    top:.25rem;
    display: inline-block;
    border: calc(1rem/16) solid #c4c6c7;
    background: #ffffff;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.nav-tabs--radio-style .nav-item.show .nav-link:before, .nav-tabs--radio-style .nav-link.active:before {
    border: 1px solid var(--color-primary);
}
.nav-tabs--radio-style .nav-item.show .nav-link:after, .nav-tabs--radio-style .nav-link.active:after {
    content: '';
    position: absolute;
    top: .575rem;
    left: .3125rem;
    width: .375rem;
    height:.375rem;
    border-radius: 50%;
    background: var(--color-primary);
}