.icon-in-text {
    vertical-align: -.12em;
}
.icon-in-text--xl {
    font-size: 1.5em;
}
.icon-in-text--lg {
    font-size: 1.2em;
}
.icon-in-text--sm {
    font-size: .8em;
}

.icon-3-5x { font-size: 3.5em; }
