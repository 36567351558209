.customer-number {
    margin:0 0 1.5rem 0;
    @media screen and (min-width: 768px) {
        margin:0 0 2.5rem 0;
    }
}

.account-box {
    font-size: .875rem;
    padding:1.25rem;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 50px 0 rgba(0,58,96,0.1);
    @media screen and (min-width: 768px) {
        height:100%;
    }
}
.account-retour__subheading,
.account-box__subheading {
    font-size: 1.125rem;
    letter-spacing: calc(1rem / 16);
}
.account-retour,
.account-wishlist,
.account-order {
    padding:1.125rem 1.25rem;
    margin:.875rem 0;
    font-size: .875rem;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 50px 0 rgba(0,58,96,0.1);
    @media screen and (min-width: 768px) {
        padding:1.5rem 2.25rem;
    }
}

@media screen and (min-width: 768px) {
    .customer-backend-collapse-icon {
        display: inline-block;
        padding: 1rem .5rem;
    }
}
.customer-backend-collapse-icon:not(.collapsed) .icon.icon {
    transform: rotate(225deg);
}
.customer-backend-collapse-icon:not(.collapsed) .icon:before {
    content:var(--icon-open);
}

.account-order-target:target {
    margin-top: -7rem;
    padding-top: 7rem;
    z-index: -999;
    position: relative;
    pointer-events: none;
}
.account-order-no-target {
    z-index: 1;
    position: relative;
}
.customer-backend__orders-article {
    padding:1.25rem 0;
    margin:0 0 1.25rem 0;
    font-size: .875rem;
    border-bottom:1px solid var(--color-sand-20);
}
.account-order__lbl {
    font-size: .75rem;
    color:var(--color-text-muted);
}
.customer-backend__orders-articles {
    margin-top:1.25rem;
    border-top: 1px solid var(--color-sand-20);
}
.customer-backend__orders-article__image {
    height: 80px;
    width: 80px;
    background-color: #F9F8F7;
    padding:.5rem;
    @media screen and (min-width: 768px) {
        height: 125px;
        width: 125px;
        padding:.875rem;
    }
}
.delivery-method-img {
    display: block;
    width: auto;
    height:1.5rem;
    margin-bottom:.75rem;
}
.dropdown-menu--account {
    border:0;
    font-size: .875rem;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.33);
}