.lightbox-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: calc(20rem / 16);
    color: var(--color-text-default);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.lightbox-btn {
    @media (hover: none) {
        opacity: .7;
    }
    @media screen and (max-width: 767px) {
        opacity: .7;
    }
}
.lightbox-item:hover .lightbox-btn {
    opacity: .7;
}
.lightbox-item {
    cursor: pointer;
}
.lg-backdrop {
    background: #fff;
}
.lg-toolbar {
    background: transparent;
}
.lg-toolbar .lg-icon {
    color: var(--color-text-default);
}
.lg-toolbar .lg-icon:hover {
    color: var(--color-primary);
}

.lg-outer .lg-item .lg-img-wrap {
    @media screen and (max-width: 767px) {
        padding: 0 calc(30rem / 16);
    }
}
.lg-sub-html {
    background: rgba(255,255,255,.8);
    color: var(--color-text-default);
}

.lg-outer .lg-img-wrap {
    padding-top: calc(45rem / 16);
    padding-bottom: calc(45rem / 16);
}