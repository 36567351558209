.checkout-sidebar {
    position: relative;
}

.checkout-sidebar__hl {
    padding-bottom:.625rem;
    margin-bottom: 0;
    border-bottom:1px solid var(--color-sand-20);
}
.checkout-sidebar__name {
    letter-spacing:.5px;
}
.checkout-sidebar__amount {
    font-size: .9375rem;
}
.checkout-sidebar__item {
    position: relative;
    padding:.625rem 0;
    font-family: var(--font-default-heading-medium);
    border-bottom:1px solid var(--color-sand-20);
}
.checkout-sidebar__image {
    background-color: #F9F8F7;
    padding:.5rem;
    @media screen and (min-width: 768px) {
        margin-right: 1rem;
        padding:.875rem;

    }
}
.checkout-sidebar__bottom__total, .checkout-summary__total-title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.checkout-sidebar__bottom {
    padding:1rem 0;
    border-bottom:1px solid var(--color-sand-20);
}
.checkout-sidebar__bottom:last-of-type {
    border-bottom:0;
}
.checkout-sidebar__bottom a:not(.btn) {
    text-decoration: underline;
}
.checkout-sidebar__bottom a:not(.btn):hover,
.checkout-sidebar__bottom a:not(.btn):focus {
    text-decoration: none;
}