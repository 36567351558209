.squarelovin {
    position: relative;
    min-height: calc(300rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(90rem / 16) calc(32rem / 16) 0;
    }
}
.squarelovin__hashtag {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(-10rem / 16);
    right: 0;
    font-size: calc(45rem / 16);
    letter-spacing: calc(4.5rem / 16);
    line-height: 1;
    opacity: .3;
    color: var(--color-sand);
    margin-top: calc(18rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        top: 0;
        transform: none;
        left: calc(32rem / 16);
        right: calc(32rem / 16);
        font-size: clamp(calc(40rem / 16), 11vw, calc(210rem / 16));
        letter-spacing: calc(15rem / 16);
    }
}
.squarelovin__result {
    margin: calc(-30rem / 16) calc(-10rem / 16) 0;
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        margin: 0 calc(-20rem / 16);
        flex-wrap: nowrap;
    }
}
.squarelovin__item {
    max-width: 50%;
    width: 100%;
    padding: 0 calc(10rem / 16);
    margin-top: calc(30rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(303rem / 16);
        padding: 0 calc(20rem / 16);
        margin-top: 0;
    }
}
.squarelovin__item:nth-child(odd) {
    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
    }
}
.squarelovin__item-img {
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.2);

    @media screen and (min-width: 768px) {
        box-shadow: 0 calc(50rem / 16) calc(100rem / 16) 0 rgba(0,0,0,0.2);
    }
}
.squarelovin__item-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(20rem / 16);
    background: rgba(0,0,0,.35);
    color: #fff;
}
.squarelovin__item:hover .squarelovin__item-content {
    opacity: 1;
}
.squarelovin__item-caption {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.squarelovin-container span.squarelovin-user-info strong,
.squarelovin-container div.squarelovin-user-info strong {
    font-weight: var(--font-default-bold-weight);
}
.squarelovin-container ul.squarelovin-stream-container.product-stream li.squarelovin-stream-item {
    @media screen and (min-width: 768px) {
        padding: 0 calc(20rem / 16) !important;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 3vw !important;
    }
}
.squarelovin-container li.squarelovin-stream-item span.squarelovin-user-info {
    @media screen and (min-width: 768px) {
        left: calc(20rem / 16);
        right: calc(20rem / 16);
        width: auto;
    }

    @media screen and (min-width: 1200px) {
        left: 3vw;
        right: 3vw;
    }
}
.squarelovin-container .squarelovin-stream-item.squarelovin-stream-item:nth-child(odd) {
    @media screen and (min-width: 768px) {
        margin-top: calc(30rem / 16);
    }
}
.squarelovin-container .squarelovin-stream-item img {
    @media screen and (min-width: 768px) {
        box-shadow: 0 calc(10rem / 16) calc(30rem / 16) 0 rgba(0,0,0,.2);
    }
}
.squarelovin-widget-container {
    @media screen and (min-width: 768px) {
        margin-top: calc(50rem / 16);
    }
}