.progress {
    height: .75rem;
    line-height: 0;
    background-color: #e9ecef;
    border-radius: .25rem;
    box-shadow: inset 0 0.1rem 0.1rem rgba(0,0,0,.1);
}
.progress-bar {
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #dc3545;
    width:2%;
    transition: width .6s ease;
}