.alert-notification--fixed {
    z-index: 11;
    pointer-events: auto;
}
.alert-notification .alert .close {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: 0;
    color: currentColor;
    padding: calc(3rem / 16);
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    cursor: pointer;
}
.alert-notification .alert .close:before {
    font-family: iconfont;
    content: var(--icon-open);
    transform: rotate(45deg);
    display: block;
}
.alert-notification .alert .close > span {
    display: none;
}

.alert-notification .alert {
    color: #fff;

    &.alert-danger {
        background: #B3603F;
        border-color: #B3603F;
    }
}

.alert-danger {
    background: #FFA988;
    border-color: #FFA988;
}

.alert-success {
    background: var(--color-success);
    border-color: var(--color-success);
}
.alert-info {
    background: var(--color-info);
    border-color: var(--color-info);
}