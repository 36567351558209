.title-block {
    margin-bottom: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(35rem / 16);
    }
}
.title-block__title-sm {
    font-size: calc(30rem/16);
    line-height: 1;
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}