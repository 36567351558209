.badge--new-color {
    background: #FFA988;
    color: #fff
}
.badge--new-product {
    background: var(--color-primary-light);
    color: #fff
}
.badge--womens-fit {
    background: #F1E746;
    color: var(--color-primary);
}

.count-badge {
    width: calc(14rem / 16);
    height: calc(14rem / 16);
    border-radius: 50%;
    font-size: calc(8rem / 16);
    line-height: 1;
    padding: calc(4rem / 16) 0;
}
.count-badge--absolute {
    position: absolute;
    bottom: calc(-2rem / 16);
    right: calc(-7rem / 16);
}