.commerceConnector__map{
    height: 60vh;
    width: 100%;
    min-height: 450px;
    max-height: 80vh;
}

.btn.commerceConnector__close{
    position: absolute;
    top: calc(10rem / 16);
    right: calc(10rem / 16);
}

.commerceConnector__container{
    max-height: 80vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: calc(20rem / 16) calc(20rem / 16) 0;

    @media screen and (min-width: 768px) {
        height: calc(60vh - 2rem);
    }
}

.commerceConnector__result {
    position: relative;
    height: 100%;
    width: 100%;
    flex: 1;
}
.commerceConnector__list {
    padding-right: calc(5rem / 16);
}
.commerceConnector__storeitem{
    cursor: pointer;
    border-bottom: calc(1rem / 16) solid var(--color-sand-20)
}
.commerceConnector__storeitem:last-child{
    border-bottom: none;
}

.commerceConnector__detail{
    height: 100%;
    width: 100%;
}

.commerceConnector__store{
    height: 100%;
    position: relative;
    display: block;
}

.btn.commerceConnector__close-store{
    position: absolute;
    top: 0;
    right: 0;
}