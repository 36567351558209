.table--checkout{
    margin:2rem 0;
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        margin:1rem 0;
    }
}

.table--checkout thead{
    background-color: transparent;
    color: #000;
    font-size: .8em;
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.table--checkout th{
    border-top: 0;
    padding:.125rem;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    border-bottom: 1px solid var(--color-sand-20) !important;
}

.table--checkout td {
    border-top: 1px solid var(--color-sand-20);
    vertical-align: middle;
    padding:1.25rem .125rem;
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        padding:.875rem 0;
        border:0;
    }
}

.table--checkout tbody {
    border-bottom: 1px solid var(--color-sand-20);
    @media screen and (max-width: 767px) {
        padding-bottom:.875rem;
    }
}

.table--checkout tr {
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        border:0;
    }
}

@media screen and (max-width: 767px) {
    .table--checkout tr td + td {
        display: none;
    }
    .table--checkout tr td:first-child {
        border-top: 1px solid var(--color-sand-20);
        margin-top: .875rem;
    }
    td.cart__number-spinner {
        display: flex!important;
        justify-content: space-between;
        border: 0;
        padding: 0;
        margin: 0;
    }
}

.table__price {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}