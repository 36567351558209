.product-img {
    padding: calc(40rem / 16);
}
.product-img__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-height: 100%;
    width: auto;
}
.product-img.product-img--fill {
    padding: 0;
}
.product-img--fill .product-img__img {
    bottom: 0;
    top: auto;
    transform: translateX(-50%);
}
.product-img-slider__nav{
    position: absolute;
    bottom: calc(10rem / 16);
    left: 0;
    right: 0;
    padding: 0 calc(70rem / 16);
}

@media screen and (max-width: 767px) {
    .product-img-slider {
        margin: 0 calc(-20rem / 16);
        padding: 0 0 calc(10rem / 16);
        background: var(--color-sand-20);
        position: relative;
    }
    .product-img-slider__color {
        border: 0;
        height: calc(40rem / 16);
        width: calc(40rem / 16);
        border-radius: 50%;
        box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);
        position: absolute;
        right: calc(20rem / 16);
        bottom: calc(20rem / 16);
        background: #fff;
        z-index: 1;
    }

    .product-img-slider__color:before,
    .product-img-slider__color:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: calc(20rem / 16);
        width: calc(20rem / 16);
        background: linear-gradient(224.4deg, #FF0000 0%, #EA8FBC 11.54%, #D019E5 22.88%, #1A00F2 35.83%, #0F8AE7 46.7%, #10E39B 59.16%, #47C50F 72.9%, #FDE41F 87.05%, #FF0000 100%);
        border-radius: 50%;
    }
    .product-img-slider__color:before {
        height: calc(14rem / 16);
        width: calc(14rem / 16);
        background: #fff;
        z-index: 1;
    }

    .product-img-slider .scrolling-area--xs::-webkit-scrollbar,
    .product-img-slider .scrolling-area::-webkit-scrollbar {
        background: #fff;
    }
    .product-img-slider__badge {
        position: absolute;
        top: calc(20rem / 16);
        left: calc(20rem / 16);
        z-index: 1;
    }
}