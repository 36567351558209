.has-background-image #main-content {
    position: relative;
}

.page-background-image-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    clip-path: inset(0 0 0 0);
}

.page-background-image {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.page-background-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.page-background-image.page-background-image--fixed-left,
.page-background-image.page-background-image--fixed-right {
    width: 50%;
}

.page-background-image.page-background-image--fixed-left {
    left: 0;
}

.page-background-image.page-background-image--fixed-right {
    right: 0;
}

.page-background-image.page-background-image--fixed-left img,
.page-background-image.page-background-image--fixed-right img{
    object-fit: contain;
}

.page-background-image.page-background-image--fixed-left img {
    object-position: left;
    font-family: 'object-fit: contain;object-position: left;';
}

.page-background-image.page-background-image--fixed-right img {
    object-position: right;
    font-family: 'object-fit: contain;object-position: right;';
}