.link-box {
    background: var(--color-sand-20);
    color: var(--color-primary);
    padding: calc(23rem / 16) calc(8rem / 16) calc(19rem / 16);
    transition: background 200ms ease-in-out;
    font-size: calc(12rem / 16);
    border-radius: calc(4rem / 16);

    @media screen and (min-height: 768px) {
        font-size: calc(16rem / 16);
        padding: calc(40rem / 16) calc(10rem / 16) calc(36rem / 16);
    }
}
.link-box:hover {
    background: var(--color-sand-10);
}

.pimcore_area_story-hub-teaser .container.container-md {
    @media screen and (min-width: 768px) {
        max-width: 80%;
    }
}

.pimcore_area_story-hub-teaser .link-box {
    padding: 1rem;
    @media screen and (min-width: 768px) {
        font-size: 0.8rem;
    }
}

.js-storyHubTeaser__category.link-box:focus, .js-storyHubTeaser__category--active {
    background: var(--color-primary);
    color: var(--color-white);
}