.mega-nav__item.has-children > .mega-nav__item-content--link {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.mega-nav__list {
    margin-bottom: 0;
}
.mega-nav__item-content--toggle {
    display: none;
}
.mega-nav__item--level-0.is-highlight > .mega-nav__item-content {
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}