:root {
    --background-color: var(--color-sand-20)
}
.highlight-area {
    position: relative;
    padding-top: calc(18rem / 16);

    @media screen and (max-width: 767px) {
        padding-left: calc(20rem / 16);
        padding-right: calc(20rem / 16);
    }
}
.highlight-area:not(.highlight-area--media):before {
    content: '';
    position: absolute;
    top: calc(18rem / 16);
    left: calc(50rem / 16);
    right: 0;
    bottom: 0;
    background: var(--background-color);

    @media screen and (min-width: 768px) {
        left: 13%;
    }
}
.highlight-area__content {
    padding: calc(20rem / 16) 0 calc(20rem / 16) calc(50rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(65rem / 16) calc(80rem / 16) calc(55rem / 16) 0;
    }
}
.highlight-area__title {
    margin-top: calc(-18rem / 16);
    font-size: calc(30rem / 16);
}
/* right align */
.highlight-area--title-right:not(.highlight-area--media):before {
    right: calc(50rem / 16);
    left: 0;

    @media screen and (min-width: 768px) {
        right: 13%;
        left: 0;
    }
}
.highlight-area--title-right .highlight-area__content {
    padding-right: calc(50rem / 16);
    padding-left: 0;

    @media screen and (min-width: 768px) {
        padding-left: calc(80rem / 16);
    }
}

/* with media content */
.highlight-area--media {
    padding: calc(18rem / 16) 10% calc(60rem / 16) 0;

    @media screen and (min-width: 768px) {
        overflow: hidden;
    }
}
.highlight-area--media-left {
    padding-right: 0;
    padding-left: 10%;
}
.highlight-area--media .highlight-area__content {
    padding: 0;
    position: relative;

    @media screen and (min-width: 768px) {
        min-height: calc(320rem / 16);
    }
}
.highlight-area--media .highlight-area__title {
    max-width: calc(440rem / 16);
}
@media screen and (min-width: 768px) {
    .highlight-area--media .highlight-area__content:before {
        content: '';
        position: absolute;
        top: 0;
        left: 23%;
        right: -999em;
        bottom: 0;
        background: var(--background-color);
    }
    .highlight-area--media-left .highlight-area__content:before {
        right: 23%;
        left: -999em;
    }
}
.highlight-area__media {
    margin-bottom: calc(-60rem / 16);
    padding-bottom: calc(100rem / 16);
    position: relative;
    z-index: 1;
}
.highlight-area__media__item--sm {
    position: absolute;
    bottom: 0;
    right: calc(20rem / 16);
    max-width: calc(140rem / 16);
    width: 100%;

    @media screen and (min-width: 768px) {
        right: 20%;
        max-width: 37%;
    }
}
.highlight-area--media-left .highlight-area__media__item--sm {
    right: 0;
    left: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        right: 0;
        left: 20%;
    }
}
@media screen and (max-width: 767px) {
    .highlight-area--media:before {
        content: '';
        position: absolute;
        top: calc(18rem / 16);
        left: calc(50rem / 16);
        right: 0;
        bottom: calc(40rem / 16);
        background: var(--color-sand-20);
    }
    .highlight-area--media-left.highlight-area--media:before {
        left: 0;
        right: calc(50rem / 16);
    }
    .highlight-area--media.highlight-area--sm-bg:before {
        top: 0;
        bottom: calc(18rem / 16);
        width: calc(200rem / 16);
        left: calc(40rem / 16);
    }
    .highlight-area--media-left.highlight-area--sm-bg:before {
        left: auto;
        right: calc(40rem / 16);
    }
}

/* small */
.highlight-area--sm-bg {
    padding: 0;
}
.highlight-area--sm-bg .highlight-area__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (min-width: 768px) {
        min-height: calc(240rem / 16);
    }
}

.highlight-area--sm-bg .highlight-area__content:before {
    width: calc(830rem / 16);
    max-width: 70vw;
    left: 26%;
}
.highlight-area--sm-bg.highlight-area--media-left .highlight-area__content:before {
    right: 26%;
    left: auto;
}
.highlight-area--sm-bg .highlight-area__title {
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-18rem / 16);
    }
}
.highlight-area--sm-bg .highlight-area__media{
    margin-top: calc(40rem / 16);
    margin-bottom: 0;
    padding-bottom: 0;

    @media screen and (max-width: 767px) {
        margin-top: calc(20rem / 16);
        margin-bottom: calc(20rem / 16);
        padding-left: calc(40rem / 16);
    }
}
.highlight-area--sm-bg.highlight-area--media-left .highlight-area__media{
    @media screen and (max-width: 767px) {
        padding-left: 0;
        padding-right: calc(40rem / 16);
    }
}