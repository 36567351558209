.temperature {
    @media screen and (max-width: 767px) {
        padding: 0 calc(20rem / 16);
    }
}
.temperature__item {
    width: 100%;
    font-size: calc(14rem / 16);
    line-height: calc(22/14);

    @media screen and (max-width: 767px) {
        min-width: 80%;
    }
}
.temperature__item + .temperature__item {
    margin-left: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(20rem / 16);
    }
}
.temperature__head {
    padding: calc(12rem / 16) calc(20rem / 16) calc(10rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(16/12);
    background: var(--color-success);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(20rem / 16) calc(16rem / 16);
    }
}
.temperature__item:first-child .temperature__head {
    border-top-left-radius: calc(34rem / 16);
    border-bottom-left-radius: calc(34rem / 16);
}
.temperature__item:last-child .temperature__head {
    border-top-right-radius: calc(34rem / 16);
    border-bottom-right-radius: calc(34rem / 16);
}
.temperature__description {color: var(--color-success);}

.temperature__value {
    font-size: calc(14rem / 16);
}
.temperature__body {
    padding: 0 calc(20rem / 16);
    margin-top: calc(15rem / 16);
}

/* colors */
.temperature__item--comfort .temperature__head {background: var(--color-red)}
.temperature__item--comfort .temperature__description {color: var(--color-red);}
.temperature__item--extreme .temperature__head {background: var(--color-primary-light)}
.temperature__item--extreme .temperature__description {color: var(--color-primary-light);}
