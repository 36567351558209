.checkmark-list {
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
    }
}
.checkmark-list li {
    position: relative;
    padding-left: calc(20rem / 16);
}
.checkmark-list li + li {
    margin-top: calc(3rem / 16);
}
.checkmark-list li:before {
    content: var(--icon-check);
    font-family: iconfont;
    font-size: calc(10rem / 16);
    color: var(--color-success);
    position: absolute;
    top: calc(2rem / 16);
    left: 0;
}