.horizontal-teaser {
    margin-right: calc(20rem / 16);
    padding-left: calc(20rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        overflow: hidden;
        margin: calc(-24rem / 16) 0 0;
        padding: calc(24rem / 16) 0 0 calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(24rem / 16) 0 0 8.33333%;
    }
}
@media screen and (max-width: 767px) {
    .horizontal-teaser:before {
        content: '';
        position: absolute;
        top: calc(16rem / 16);
        left: 0;
        right: calc(30rem / 16);
        bottom: 0;
        background: var(--color-sand-20);
    }
}

.horizontal-teaser__title {
    position: relative;
    z-index: 1;
    font-size: calc(38rem/16);
    margin-left: auto;
    max-width: 75%;
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(-22rem / 16);
        max-width: 63%;
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(56rem/16);
        margin-bottom: calc(-35rem / 16);
    }
}

.horizontal-teaser__img {
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        width: 100%;
        max-width: calc(688rem / 16);
        margin-bottom: calc(40rem / 16);
    }
}
.horizontal-teaser__content {
    padding-right: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: 18.2%;
        background: none;
    }
}
.horizontal-teaser__content__text {
    position: relative;
    padding: calc(24rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(20rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(65rem / 16) 24% calc(65rem / 16) calc(70rem / 16);
    }
}
.horizontal-teaser__content__text:before {
    @media screen and (min-width: 768px) {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        background: var(--color-sand-20);
        left: -9999em;
        right: 0
    }
}

/* media right */
.horizontal-teaser--media-right {
    margin-right: 0;
    margin-left: calc(20rem / 16);
    padding-left: 0;
    padding-right: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding-left: 0;
        padding-right: calc(30rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding-left: 0;
        padding-right: 8.33333%;
    }
}
@media screen and (max-width: 767px) {
    .horizontal-teaser--media-right.horizontal-teaser:before {
        right: 0;
        left: calc(30rem / 16);
    }
}
.horizontal-teaser--media-right .horizontal-teaser__content {
    padding-right: 0;
    padding-left: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: 0;
        padding-left: 18.2%;
    }
}
.horizontal-teaser--media-right .horizontal-teaser__content__text:before {
    @media screen and (min-width: 768px) {
        left: 0;
        right: -999em;
    }
}
.horizontal-teaser--media-right .horizontal-teaser__title {
    margin-left: 0;
    margin-right: auto;
}