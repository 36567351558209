.form-errors.invalid-feedback {
    display: block;
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin-top: .25rem;
    font-size: .875em;
}

.form-errors ul,
.form-errors ~ .pristine-error {
    color: var(--color-danger);
}

.error-list, .parsley-errors-list {
    padding-left: 0;
    list-style: none;
}

.error-list__message, .parsley-custom-error-message{
    content:'!';
    padding: .5rem;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    color: var(--color-danger);
    margin-right:.25rem;
}

.unzerUI.form .error-list__message,
.unzerUI.form .parsley-custom-error-message {
    font-family: inherit;
    text-transform: none;

    padding: 0;
    margin-top: 0.5rem;
}

.unzerUI.form .error-list__message,
.unzerUI.form .parsley-custom-error-message,
.unzerUI.parsley-error label,
.unzerUI.parsley-error + label,
.unzerUI.parsley-error label a,
.unzerUI.parsley-error + label a,
.unzerUI.input > .unzerInput.parsley-error,
.unzerUI.input > input.parsley-error,
.unzerUI.input > input.parsley-error + label,
.unzerUI.input > input.parsley-error + label a {
    color: var(--color-danger);
}

.unzerUI.input > .unzerInput.parsley-error,
.unzerUI.input > input.parsley-error {
    background: #fff6f6;
    border-color:var(--color-danger);;
    box-shadow: none;
}

.js-paymentform .unzerUI.form .error.message,
.js-paymentform .unzerUI.form .success.message,
.js-paymentform .unzerUI.form .warning.message {
    min-height: auto;
}
