.range-slider__slider {
    height: calc(4rem / 16);
    border-radius: calc(2rem / 16);
    border: 0;
    box-shadow: none;
    background: var(--color-sand-10);
}
.range-slider__slider .noUi-handle {
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    background: var(--color-primary);
    border-radius: 50%;
    cursor: pointer;
    top: calc(-6rem / 16);
    border: 0;
    box-shadow: none;
}
.range-slider__slider .noUi-handle:before,
.range-slider__slider .noUi-handle:after {
    content: none;
}
.range-slider--with-data-text .range-slider__slider .noUi-handle:before {
    content: attr(data-text);
    font-size: .875rem;
    position: absolute;
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    width: auto;
    white-space: nowrap;
}

.range-slider__slider .noUi-handle:focus {
    outline: none;
}
html:not([dir=rtl]) .range-slider__slider.noUi-horizontal .noUi-handle {
    right: calc(-8rem / 16);
}
.range-slider__slider .noUi-connect {
    background: var(--color-primary);
    box-shadow: none;
    height: calc(4rem / 16);
}

.range-slider__input {
    background: none;
    border: 0;
    color: currentColor;
    cursor: default;
}