/* Global variables and settings */
@import url(./style/variables.css);
@import url(./style/icons.css);
@import url(./style/mediaqueries.css);
@import url(./style/font-face.css);
/* General base styling & reset (mostly plain element styling; very low specificity ) */
@import url(./style/base.css);
@import url(./style/base-layout.css);
@import url(./style/base-typo.css);
@import url(./style/base-ratio.css);
/* navbar */
@import url(./style/mega-nav/main-navbar.css);
@import url(./style/mega-nav/mega-nav.css);
@import url(./style/mega-nav/mega-nav--level-0-desktop.css);
@import url(./style/mega-nav/mega-nav--level-1-desktop.css);
@import url(./style/mega-nav/mega-nav-mobile.css);
@import url(./style/mega-nav/navbar-dropdown-sm.css);
@import url(./style/metabar.css);
/* Generic helper classes (mostly layout) */
@import url(./style/row--gutter.css);
@import url(./style/stretch-link.css);
/* shared */
@import url(../shared/cookie-overlay/cookie-overlay.css);
@import url(../shared/unhide-by-login-state/unhide-by-login-state.css);
/* Components */
@import url(./style/btn.css);
@import url(./style/icon-btn.css);
@import url(./style/custom-checkbox.css);
@import url(./style/custom-radio.css);
@import url(./style/form-errors.css);
@import url(./style/form.css);
@import url(./style/number-spinner.css);
/*@import './style/floating-label.css';*/
@import url(./style/select.css);
@import url(./style/range-slider.css);
@import url(./style/content-block.css);
@import url(./style/slide.css);
@import url(./style/img-slide.css);
@import url(./style/title-block.css);
@import url(./style/wysiwyg.css);
@import url(./style/skip-links.css);
@import url(./style/icon-in-text.css);
@import url(./style/loading-overlay.css);
@import url(./style/loading-spinner.css);
@import url(./style/hero.css);
@import url(./style/link-box.css);
@import url(./style/horizontal-teaser.css);
@import url(./style/img-teaser.css);
@import url(./style/text-banner.css);
@import url(./style/scrolling-area.css);
@import url(./style/accordion.css);
@import url(./style/highlight-area.css);
@import url(./style/product-slide.css);
@import url(./style/language-switch.css);
@import url(./style/modal.css);
@import url(./style/tabs.css);
@import url(./style/account.css);
@import url(./style/shipping-payment.css);
@import url(./style/progress.css);
@import url(./style/tabs.css);
@import url(./style/product-teaser.css);
@import url(./style/teaser-slider.css);
@import url(./style/color-badge.css);
@import url(./style/productgrid.css);
@import url(./style/overlay.css);
@import url(./style/job-teaser.css);
@import url(./style/grid-content-teaser.css);
@import url(./style/cart-teaser.css);
@import url(./style/cart.css);
@import url(./style/back-link.css);
@import url(./style/checkout.css);
@import url(./style/checkout-sidebar.css);
@import url(./style/checkout-steps.css);
@import url(./style/table-checkout.css);
@import url(./style/summary.css);
@import url(./style/pagination.css);
@import url(./style/history-slider.css);
@import url(./style/product-colors.css);
@import url(./style/checkmark-list.css);
@import url(./style/compare-list.css);
@import url(./style/hotspot-area.css);
@import url(./style/content-box.css);
@import url(./style/rating.css);
@import url(./style/video.css);
@import url(./style/product-img-slider.css);
@import url(./style/typeahead.css);
@import url(./style/page-alert.css);
@import url(./style/badge.css);
@import url(./style/wishlist.css);
@import url(./style/affix.css);
@import url(./style/anchor-menu.css);
@import url(./style/alert-notification.css);
@import url(./style/sticky-btn-bar.css);
@import url(./style/background-teaser.css);
@import url(./style/customer-tabs.css);
@import url(./style/temperature.css);
@import url(./style/commerce-connector.css);
@import url(./style/bottle-animation.css);
@import url(./style/card-teaser.css);
@import url(./style/lightbox.css);
@import url(./style/squarelovin.css);
@import url(./style/scrolling-map.css);
@import url(./style/location-teaser.css);
@import url(./style/embed.css);
@import url(./style/anchor.css);
@import url(./style/embed-responsive.css);
@import url(./style/hero-slider.css);
@import url(./style/hero-slider2.css);
@import url(./style/pds.css);
@import url(./style/pds-additional-info.css);
@import url(./style/raffle.css);
@import url(./style/dealer-locator.css);
@import url(./style/outtra-button-widget.css);
@import url(./style/product-advisor.css);
@import url(./style/splide-tabs.css);
@import url(./style/participants.css);
@import url(./style/page-background-image.css);
@import url(./style/rally.css);
@import url(./style/bubbles.css);
@import url(./style/banner-text-image.css);
@import url(./style/search.css);
@import url(./style/pro-access-info.css);
@import url(./style/pdsLightbox.css);
@import url(./style/jobs.css);
@import url(./style/oocss.css);
@import url(./style/vertical-gutter.css);
.teaser-cards-slider .splide__arrow:hover:not(:disabled) {
  opacity: .9; }

.teaser-cards-slider .splide__arrow--prev {
  left: 1em; }
  .teaser-cards-slider .splide__arrow--prev svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }

.teaser-cards-slider .splide__arrow--next {
  right: 1em; }

.teaser-cards-slider .splide__arrow {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  height: 2em;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2em;
  z-index: 1; }
  .teaser-cards-slider .splide__arrow svg {
    fill: #000;
    height: 1.2em;
    width: 1.2em; }

.content-teaser__content {
  padding: calc(24rem / 16) calc(20rem / 16) 0; }
  @media screen and (min-width: 768px) {
    .content-teaser__content {
      padding: calc(20rem / 16) 0 0; } }

.content-teaser__title {
  font-size: calc(20rem / 16);
  margin-bottom: 0; }

.content-teaser__text {
  margin-top: calc(20rem / 16); }

.content-teaser__img {
  transition: transform 200ms ease-in-out; }

.content-teaser:hover .content-teaser__img {
  transform: scale(1.02); }

footer {
  padding: .125rem 0;
  font-size: .875rem;
  background: var(--color-light-grey); }

/* footer form */
.footer__form-wysiwyg {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 1.125rem; }

.footer__form-wysiwyg .wysiwyg {
  font-size: .875rem; }

@media screen and (max-width: 767px) {
  .footer__forms-col:first-of-type {
    margin-bottom: 2rem; } }

.footer__logos,
.footer__social,
.footer__servicelinks,
.footer__forms,
.seo-footer {
  margin: 5rem 0; }
  @media screen and (max-width: 767px) {
    .footer__logos,
    .footer__social,
    .footer__servicelinks,
    .footer__forms,
    .seo-footer {
      margin: 2rem 0; } }

@media screen and (max-width: 767px) {
  .footer__logos,
  .seo-footer {
    margin-bottom: .875rem; } }

@media screen and (max-width: 991px) {
  .footer__servicelinks-col {
    margin-bottom: 1.5rem; }
  .footer__servicelinks-col:last-of-type {
    margin-bottom: 0; } }

.footer__dotlist a:hover,
.footer__dotlist a:focus {
  text-decoration: underline; }

.footer__dotlist.footer__dotlist > li:not(:last-child) {
  position: relative;
  margin-right: .625rem;
  padding-right: .625rem; }

.footer__dotlist > li:not(:last-child):after {
  content: '';
  position: absolute;
  right: -.125rem;
  top: .5rem;
  display: inline-block;
  width: .125rem;
  height: .125rem;
  border-radius: 100%;
  background: var(--color-text-default); }

/* social list */
.footer__social-list {
  margin: 0 auto; }

.footer__social-list.footer__social-list > li {
  margin: 0 1.5rem; }
  @media screen and (max-width: 767px) {
    .footer__social-list.footer__social-list > li {
      margin: 0 .875rem; } }

.footer__social-list.footer__social-list > li a {
  color: var(--color-primary);
  font-size: 1.5rem; }
  @media screen and (max-width: 767px) {
    .footer__social-list.footer__social-list > li a {
      font-size: 1.25rem; } }

.footer__social-list.footer__social-list > li a .icon-facebook {
  font-size: 1.625rem; }
  @media screen and (max-width: 767px) {
    .footer__social-list.footer__social-list > li a .icon-facebook {
      font-size: 1.375rem; } }

/* logo list */
.footer__logolist {
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .footer__logolist {
      text-align: center; } }

.footer__logolist.footer__logolist > li {
  margin: 0 1.5rem; }
  @media screen and (max-width: 767px) {
    .footer__logolist.footer__logolist > li {
      margin: 0 .875rem; } }

.footer__logolist-img {
  display: inline-block;
  width: auto;
  height: 2.5rem; }
  @media screen and (max-width: 767px) {
    .footer__logolist-img {
      height: 1rem; } }

.foot {
  padding: 2rem 0;
  border-top: 1px solid;
  font-size: .75rem; }
  @media screen and (max-width: 767px) {
    .foot {
      padding: .875rem 0;
      text-align: center; } }

/* link list */
.foot__link-list {
  margin: .5rem 0; }

@media screen and (max-width: 767px) {
  .foot__link-list > li:first-child {
    display: block;
    text-align: center;
    margin: 0 auto .5rem auto; } }

.foot__link-list > li:not(:last-child) {
  position: relative;
  margin-right: .25rem;
  padding-right: .5rem; }

.foot__link-list > li:not(:first-child):after {
  content: '';
  position: absolute;
  right: 0;
  top: .5rem;
  display: inline-block;
  width: .125rem;
  height: .125rem;
  border-radius: 100%;
  background: #333; }

.foot__link-list a:not(.btn) {
  text-decoration: none;
  color: #333; }

.foot__link-list.color-dark a:not(.btn) {
  color: #333; }

.foot__link-list a:not(.btn):hover,
.foot__link-list a:not(.btn):focus {
  text-decoration: underline; }

/* image list */
.foot__image-list {
  margin: .5rem 0; }
  @media screen and (max-width: 767px) {
    .foot__image-list {
      text-align: center; } }

.foot__image-list.foot__image-list > li {
  margin-right: 0;
  margin-left: 2.5rem; }
  @media screen and (max-width: 767px) {
    .foot__image-list.foot__image-list > li {
      margin: 0 .5rem; } }

.foot__image-list > li:first-child {
  margin-left: 0; }

.foot__image-list-img {
  display: inline-block;
  width: auto;
  height: 1rem;
  filter: grayscale(100%); }
  @media screen and (max-width: 767px) {
    .foot__image-list-img {
      height: .75rem; } }

.seo-footer {
  display: flex;
  justify-content: center; }

.seo-footer__select {
  font-size: .875rem;
  border: 0;
  padding-right: 1.25rem;
  background-color: transparent;
  background-position: center right; }

#js-seo-footer__accordion-toggle {
  position: fixed;
  top: 0;
  left: -9999px;
  z-index: -1; }

.seo-footer__accordion {
  position: relative; }

.seo-footer__accordion .icon {
  transform: rotate(-90deg); }

.seo-footer__accordion-inner {
  position: absolute;
  left: 50%;
  bottom: 100%;
  width: 500px;
  max-height: 0;
  transform: translate(-50%);
  overflow: hidden;
  z-index: 1; }

.seo-footer__accordion-inner-toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2; }

.seo-footer__accordion-inner-toggle,
.seo-footer__accordion-label {
  cursor: pointer; }

#js-seo-footer__accordion-toggle:checked + .seo-footer__accordion .seo-footer__accordion-inner {
  max-height: none; }

#js-seo-footer__accordion-toggle:checked + .seo-footer__accordion .seo-footer__accordion-label .icon {
  transform: rotate(90deg) translateY(7px); }

/* content */
.tab-module_nav-tabs {
  border: none;
  justify-content: center; }

.tab-module_nav-link, .tab-module_nav-link.active {
  background-color: transparent !important;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  border-radius: 0;
  transition: opacity 0.15s linear; }

.tab-module_nav-link.active .tab-module_tab-label .h1, .tab-module_nav-link.active .h2, .tab-module_nav-link.active .h3, .tab-module_nav-link.active .h4, .tab-module_nav-link.active .h5, .tab-module_nav-link.active .h6, .tab-module_nav-link.active h1, .tab-module_nav-link.active h2, .tab-module_nav-link.active h3, .tab-module_nav-link.active h4, .tab-module_nav-link.active h5, .tab-module_nav-link.active h6 {
  font-weight: bold !important; }

.tab-module_nav-item {
  display: flex;
  flex-direction: column;
  width: 130px;
  text-align: center;
  position: relative; }

.tab-module_nav-link:not(.active), .tab-module_nav-link:not(.active) ~ * {
  opacity: 0.5; }

.tab-module_tab-label {
  margin-top: 1em;
  margin-bottom: 0.5em;
  word-break: break-word; }
  @media screen and (min-width: 1920px) {
    .tab-module_tab-label {
      font-size: calc(27rem / 16); } }
  @media screen and (max-width: 767px) {
    .tab-module_tab-label {
      font-size: calc(14rem / 16); } }

.tab-module_bottom-line {
  margin-top: 3px;
  width: 100%;
  border: 2.5px solid;
  border-radius: 8px; }

.tab-module_bottom-line--small {
  border: 1px solid;
  width: 60%;
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -5px;
  left: 50%; }

/* desktop modifications */
@media screen and (min-width: 768px) {
  .tab-module_nav-item {
    width: 256px; }
  .tab-module_nav-item + .tab-module_nav-item {
    margin-left: 20px; } }

/* content adjusts inside a tab */
.tab-module_tab-content > div > div .container, .tab-module_tab-content > div > div .container-fluid, .tab-module_tab-content > div > div .container-lg, .tab-module_tab-content > div > div .container-md, .tab-module_tab-content > div > div .container-sm, .tab-module_tab-content > div > div .container-xl, .tab-module_tab-content > div > div .container-xxl, .tab-module_tab-content > div > div .container-xxxl {
  padding-right: 0;
  padding-left: 0; }

/* content */
/* content */
.tab-module_nav-tabs {
  border: none;
  justify-content: center; }

.tab-module_nav-link, .tab-module_nav-link.active {
  background-color: transparent !important;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  border-radius: 0;
  transition: opacity 0.15s linear; }

.tab-module_nav-link.active .tab-module_tab-label .h1, .tab-module_nav-link.active .h2, .tab-module_nav-link.active .h3, .tab-module_nav-link.active .h4, .tab-module_nav-link.active .h5, .tab-module_nav-link.active .h6, .tab-module_nav-link.active h1, .tab-module_nav-link.active h2, .tab-module_nav-link.active h3, .tab-module_nav-link.active h4, .tab-module_nav-link.active h5, .tab-module_nav-link.active h6 {
  font-weight: bold !important; }

.tab-module_nav-item {
  display: flex;
  flex-direction: column;
  width: 130px;
  text-align: center;
  position: relative; }

.tab-module_nav-link:not(.active), .tab-module_nav-link:not(.active) ~ * {
  opacity: 0.5; }

.tab-module_tab-label {
  margin-top: 1em;
  margin-bottom: 0.5em;
  word-break: break-word; }
  @media screen and (min-width: 1920px) {
    .tab-module_tab-label {
      font-size: calc(27rem / 16); } }
  @media screen and (max-width: 767px) {
    .tab-module_tab-label {
      font-size: calc(14rem / 16); } }

.tab-module_bottom-line {
  margin-top: 3px;
  width: 100%;
  border: 2.5px solid;
  border-radius: 8px; }

.tab-module_bottom-line--small {
  border: 1px solid;
  width: 60%;
  border-radius: 8px;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -5px;
  left: 50%; }

/* desktop modifications */
@media screen and (min-width: 768px) {
  .tab-module_nav-item {
    width: 256px; }
  .tab-module_nav-item + .tab-module_nav-item {
    margin-left: 20px; } }

/* content adjusts inside a tab */
.tab-module_tab-content > div > div .container, .tab-module_tab-content > div > div .container-fluid, .tab-module_tab-content > div > div .container-lg, .tab-module_tab-content > div > div .container-md, .tab-module_tab-content > div > div .container-sm, .tab-module_tab-content > div > div .container-xl, .tab-module_tab-content > div > div .container-xxl, .tab-module_tab-content > div > div .container-xxxl {
  padding-right: 0;
  padding-left: 0; }

.text-colorset-blue.tab-module_heading, .text-colorset-blue.tab-module_heading .heading {
  color: var(--color-primary) !important; }

.text-colorset-blue.tab-module_nav-tabs .tab-module_tab-label,
.text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link {
  color: var(--color-primary) !important; }

.text-colorset-blue.tab-module_nav-tabs .tab-module_bottom-line {
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary) !important; }

.text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link.active {
  color: var(--color-primary) !important; }
  .text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link.active .tab-module_tab-label {
    color: var(--color-primary) !important; }
  .text-colorset-blue.tab-module_nav-tabs .tab-module_nav-link.active ~ .tab-module_bottom-line {
    border-color: var(--color-primary) !important; }

.modal--country-mismatch-popup .modal-body {
  padding: 1rem; }

.modal--country-mismatch-popup .js-countryMismatchPopup__close {
  font-size: .85rem; }

.modal--country-mismatch-popup .js-countryMismatchPopup--select-all {
  line-height: 1;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 2px; }

.modal--country-mismatch-popup label {
  font-size: calc(13rem/16); }

.modal--country-mismatch-popup img.countryMismatchPopup_icon {
  margin-right: 5px; }

.modal--country-mismatch-popup .language-switcher > div {
  flex: 1; }

.modal--country-mismatch-popup .language-switcher div:last-child {
  margin-left: 1rem; }

.modal--country-mismatch-popup .language-switcher input[type="radio"] {
  display: none; }
  .modal--country-mismatch-popup .language-switcher input[type="radio"]:not(:disabled) ~ label {
    cursor: pointer; }

.modal--country-mismatch-popup .language-switcher label {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  outline: 1px solid #dee2e6;
  padding: 0.85rem;
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
  justify-content: center; }

.modal--country-mismatch-popup .language-switcher input[type="radio"]:checked + label {
  outline: 2px solid #3c3c3b; }
  .modal--country-mismatch-popup .language-switcher input[type="radio"]:checked + label::after {
    font-family: iconfont;
    content: var(--icon-check);
    position: absolute;
    top: 14px;
    right: 20px;
    display: inline-block;
    border-radius: 500px;
    font-size: 6px;
    padding: 5px;
    background: #292928;
    color: #fff;
    width: 18px;
    height: 18px; }

.friendlycaptcha {
  z-index: 1; }
  .friendlycaptcha .frc-container {
    font-size: .75rem;
    min-height: 46px; }
  .friendlycaptcha .frc-container svg.frc-icon {
    width: 28px;
    height: 28px;
    margin-top: 0; }
  .friendlycaptcha .frc-button {
    background: transparent;
    text-align: right;
    padding-right: 0; }
  .friendlycaptcha .frc-content {
    margin-right: 0;
    margin-top: 0; }
  .friendlycaptcha .frc-banner {
    margin-right: -6px; }
  .friendlycaptcha .frc-text {
    text-align: right; }
  .friendlycaptcha .grecaptcha-badge {
    visibility: hidden; }

/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
