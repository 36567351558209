.stage__button-bar {
    background: #FFFFFFCC;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFFCC 13%, #FFFFFFCC 100%) 0% 0% no-repeat padding-box;
}

.craue_formflow_buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: calc(12rem / 16);
}
.wizard {
    display: flex;
}

.wizard__step {
    flex: 1;
    text-align: center;
}

.wizard__dot {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
}

.wizard__connector {
    flex: 1;
    height: 2px;
    background-color: var(--color-sand-20);
}

.wizard__step:first-child .wizard__connector:first-child,
.wizard__step:last-child .wizard__connector:last-child {
    background-color: transparent;
}

.wizard__number {
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: var(--color-sand-20);
    color: var(--color-sand);
    border-radius: 9999px;
    height: 2rem;
    width: 2rem;
}

.wizard__label {
    color: var(--color-sand)
}

.wizard__current-step-label {
    color: var(--color-primary);
}

.wizard__current-step-number {
    background: var(--color-primary);
    color: #fff;
}

.anniverse-rally-registration #anniverseRegistration_shareType {
    display: flex;
}
.anniverse-rally-registration #anniverseRegistration_shareType div:not(:first-child) {
    margin-left: calc(20rem / 16);
}

.anniverse-rally-registration #anniverseRegistration_reportType .custom-checkbox {
    margin-bottom: 0.5rem;
}

.anniverse-rally-registration #anniverseRegistration_reportType .custom-checkbox:last-child {
    margin-bottom: 0;
}

.anniverse-rally-registration ul.summary-list li {
    padding-top: calc(10rem / 16);
    padding-bottom: calc(14rem / 16);
}

.anniverse-rally-registration ul.summary-list li:nth-of-type(odd) {
    border-top: 1px solid var(--color-sand-20);
    border-bottom: 1px solid var(--color-sand-20);
}

.anniverse-rally-registration ul.summary-list li:last-child {
    border-bottom: 1px solid var(--color-sand-20);
}

.anniverse-rally-registration label.required:after {
    content: "*";
    margin-left: 1px;
}

.anniverse-rally-registration .form-group {
    margin-bottom: calc(14rem / 16);
}

.anniverse-rally-registration .sticky-button-bar {
    margin-left: calc(-20rem / 16);
    margin-right: calc(-20rem / 16);
    background: #fff;
    background: linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 32%, #FFFFFF 100%);
}

.pimcore_area_content-participants .sticky-button-bar {
    background: #fff;
    background: linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 32%, #FFFFFF 100%);
}

.pimcore_area_content-participants .slide.container {
    padding: 0 calc(10rem / 16);
}

.anniverse-participants .splide__arrow {
    opacity: 1;
}

.anniverse-participants .splide__arrows button {
    box-shadow: 0 10px 50px rgba(0, 58, 96, 0.2);
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
}

.anniverse-participants .splide__arrows button:focus:not(:focus-visible) {
    box-shadow: 0 10px 50px rgba(0, 58, 96, 0.2);
}

.anniverse-participants .splide__arrows button:hover {
    background: var(--color-primary-light);
    color: #fff;
}

.anniverse-participants .splide__arrow svg {
    fill: none;
    height: 20px;
    width: 20px;
}

.anniverse-participants .splide__arrow:disabled {
    opacity: 0;
}

.anniverse-participants.splide:not(.is-overflow) .splide__arrows {
    display: none !important;
}

@media screen and (min-width: 768px) {
    .anniverse-participants .splide__arrow--prev {
        left: 0;
    }
    .anniverse-participants .splide__arrow--next {
        right: 0;
    }

    .anniverse-participants .splide__arrows {
        position: absolute;
        transform: translate(50%,-50%);
        right: 0;
        left: calc(-72rem / 16);
        bottom: calc(-95rem / 16);
    }

    .anniverse-participants .splide__track {
        overflow: visible;
    }
}

@media screen and (max-width: 767px) {
    .anniverse-participants .splide__arrows button {
        width: calc(62rem /16);
        font-size: 20px;
        margin: 0!important;
    }

    .anniverse-participants .splide__arrow {
        position: absolute;
    }

    .anniverse-participants .splide__arrow .icon {
        transform: rotate(270deg) translateY(50%) translateX(0%);
        transform-origin: -50%;
        margin-left: -5px;
    }

    .anniverse-participants .collapse-row {
        margin-right: calc(-10rem / 16);
        margin-left: calc(-10rem / 16);
    }

    .anniverse-participants .splide__track {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-left: calc(-10rem / 16);
        margin-right: calc(-10rem / 16);
    }

    .anniverse-participants .splide__slide.is-prev,
    .anniverse-participants .splide__slide.last-on-page:not(.is-visible) {
        opacity: 0;
    }

    .anniverse-participants .splide__track {
        transition: height .2s;
    }

    .anniverse-participants .splide__arrows--ttb .splide__arrow {
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
    }

    .anniverse-participants .splide__arrows--ttb .splide__arrow--next {
        bottom: 3em;
        top: auto;
    }
    .anniverse-participants .splide__arrows--ttb .splide__arrow--prev {
        top: 2em;
    }

    .anniverse-participants .collapsing {
        transition: none;
    }
}