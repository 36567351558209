.affix__placeholder--no-height {
    min-height: unset !important;
    height: 0;
    position: relative;
}
@media screen and (min-width: 768px){
    .affix-item--visible-affix {
        opacity: 0;
        background: #fff;
        transition: opacity 200ms ease-in-out;
        position: absolute;
        left: 0;
        right: 0;
    }
    .affix-item--visible-affix.is-affix--bottom,
    .affix-item--visible-affix.is-affix {
        z-index: 1;
        opacity: 1;
    }
}