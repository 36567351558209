.hotspot-area {
    padding: calc(65rem / 16) calc(40rem / 16) calc(20rem / 16);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
        padding: calc(100rem / 16) calc(40rem / 16) calc(80rem / 16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(140rem / 16) calc(40rem / 16);
    }
}
.hotspot-area__bg {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.hotspot-area__title {
    position: absolute;
    left: calc(20rem / 16);
    top: calc(20rem / 16);
    font-size: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(32rem / 16);
        left: calc(38rem / 16);
        top: calc(38rem / 16);
    }
}
.hotspot-area__container {
    position: relative;
    height: auto;
    max-height: 100%;

    @media screen and (min-width: 768px) {
        max-width: 100%;
    }
}
.hotspot-area__img {
    max-height: 100%;
    height: auto;
    width: 100%;
    object-fit: contain;
}

/* hotspot button */
.hotspot {
    width: calc(24rem / 16);
    height: calc(24rem / 16);
    border-radius: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    background: none;
    border: 0;
    padding: 0;
}
.hotspot:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background: #fff;
    width: calc(12rem / 16);
    height: calc(12rem / 16);
    border: calc(1rem / 16) solid var(--color-primary);
    border-radius: 50%;
    z-index: 1;
}
.hotspot:after {
    content: '';
    background: var(--color-primary);
    opacity: .5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    transition: background 200ms ease-in-out;
}
.hotspot:hover:after {
    background: #fff;
}
