.img-teaser__title {
    font-size: calc(24rem / 16);
    letter-spacing: calc(1rem / 16);
    margin-top: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(20rem / 16);
        font-size: calc(32rem / 16);
    }
}
.img-teaser__img {
    transition: transform 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.02);
}