.product-grid__sort-select {
    display: inline-block;
    width: auto;
    font-size: .75rem;
    padding: 0;
    padding-right: calc(20rem / 16);
    background: transparent;
    text-align-last: right;
}
.product-grid__sort-select-wrapper {
    position: relative;
}
.product-grid__sort-select-wrapper:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(8rem / 16);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    color: var(--color-primary);
}

@media screen and (min-width: 768px) {
    #product-grid-filter-sidebar {
        padding-right: 2.25rem !important;
    }
}
.sidebar__categories {
    padding:0;
    border-top: 1px solid var(--color-sand-20);
    border-bottom: 1px solid var(--color-sand-20);
    margin-bottom: calc(16rem / 16);

    @media screen and (min-width: 768px){
        border-top: 0;
        margin:3.25rem 0;
    }
}
.sidebar__categories-list {
    padding: 0 calc(10rem / 16);
}
.sidebar__categories-list>li>a {
    font-size: .875rem;
    padding: .25rem 0 0 0;
    margin-bottom: .5rem;
    line-height: 1.1;
    display: inline-block;
    border-bottom:1px solid transparent;
}
.sidebar__categories-list>li.active>a {
    color:var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
}
.sidebar__categories-list>li>a:hover,
.sidebar__categories-list>li>a:focus {
    border-bottom: 1px solid;
    color:var(--color-primary);
}
.sidebar__intro {
    padding:0 !important;
    margin:2rem 0 0 0 !important;
}
.sidebar__hl {
    text-transform: uppercase;
    font-family: var(--font-default-compact);
    font-style: var(--font-default-compact-style);
    color: var(--color-primary);
    letter-spacing: 0.5px;
    margin-bottom:0;
    padding-bottom: .75rem;
    font-weight: var(--font-default-compact-weight);
    border-bottom: 1px solid var(--color-sand-20);
}
.sidebar__hl--filter {
    margin-top:3.25rem;
}
.sidebar__hl .icon {
    position: relative;
    top:0;
    font-size:.875rem;
    margin-right:.5rem;
}



/* collapsing filter */
.btn.product-grid__filter-collapse {
    display: flex;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 1;
    padding:1.125rem .625rem;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    position: relative;
    box-shadow: none;
}
.product-grid__filter-icon {
    position: absolute;
    right:.625rem;
    top:.875rem;
}
.product-grid__filter-icon:before {
    font-size: .5rem;
    color:#A4A2A1;
}
.product-grid__filter-collapse:not(.collapsed) .product-grid__filter-icon:before {
    content:var(--icon-close);
}
.product-grid__legend {
    font-size: .875rem;
    margin:0;
    display: block;
    width: 100%;

    @media screen and (min-width: 768px){
        font-size: 1rem;
    }
}
.product-grid__legend-filters {
    font-size: calc(11rem / 16);
    margin-top: calc(2rem / 16);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
.product-grid__filter-collapse-target {
    padding:0 .625rem .75rem .625rem;
}
.product-grid__filter-collapse__container {
    border-top: 1px solid var(--color-sand-20);
    border-bottom: 1px solid var(--color-sand-20);
}
.product-grid__filter-collapse__container +.product-grid__filter-collapse__container {
    border-top: 0;
}
.product-grid__back-icon {
    font-size: calc(10rem / 16);
    margin-right: calc(6rem / 16);
    margin-top: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(10rem / 16);
    }
}

/*MOBILE*/
@media screen and (max-width: 767px){
    .sidebar__categories__hl {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
}