.hero-slider__imgcontainer{
    position: relative;
    overflow: hidden;
    max-height: 615px;
    height: auto;
    width: 100%;
    background-color: black;
}

.hero-slider__slider{
    max-height: 615px;
}

.hero-slider__imgcontainer .embed-responsive.embed-responsive-absolute{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transform-origin: center center;
}

.hero-slider__slider .slick-slide > div {
    line-height: 0;
}

.hero-slider__navbar{
    background-color: var(--color-primary);
}

.hero-slider__dots{
    height: 40px;
    display: flex;
    align-items: center;
}

.hero-slider__arrow.slick-arrow.slick-arrow.slick-arrow.slick-arrow{
    padding: 0 0.5em;
    position: relative;
    top: .2em;
    color: #fff;
    display: block !important;
    transform: none;
}
.hero-slider__dots li{
    margin: 0 .5em;
    line-height: 0;
}
.hero-slider__dots li button{
    font-size: 0;
    text-indent: -999px;
    border-radius: 0;
    width: 18px;
    height: 18px;
    transform-origin: center;
    transition-duration: .33s;
    transition-property: transform;
    transform: scale(.8)
}

.hero-slider__dots .slick-active button {
    border: none !important;
    transform: scale(1.2)
}