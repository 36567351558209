/* base stylings */
.form-control {
    &::placeholder {
        color: #666;
    }

    border:0;
    font-size: calc(12rem / 16);
    border-radius: .25rem;
    padding: calc(15rem / 16) calc(16rem / 16) calc(12rem / 16);
    background: #fff;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.2);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(20rem / 16) calc(12rem / 16);
        font-size: .875rem;
    }
}
.form-control:focus {
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.35);
}
.form-control.is-valid, .was-validated .form-control:valid,
.form-control.is-invalid, .was-validated .form-control:invalid {
    background: transparent;
}

.form-label {
    margin-bottom: 0;
    color:var(--color-text-default);
    font-size: .75rem;
}


.form-control--bordered {
    border: calc(1rem / 16) solid var(--color-sand-20);
}
.form-control--grey {
    background: var(--color-sand-20);
    box-shadow: none;
}
.form-group {
    margin-bottom: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(30rem / 16);
    }
}

/* sizes */
.form-control.form-control-sm {
    font-size: calc(12rem / 16);
    padding: calc(7rem / 16) calc(10rem / 16) calc(5rem / 16);
}

/* addons */
.form-control-icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0 calc(10rem / 16);
    text-decoration: none;
    font-size: calc(10rem / 16);
    background: none;
    border: 0;
    color: currentColor;
    display: flex;
}
.form-control-icon + .form-control {
    padding-left: calc(25rem / 16);
}

.form-control-icon--right {
    left: auto;
    right: 0;
    padding: 0 calc(20rem / 16);
}
.form-control-icon--right + .form-control {
    padding-left: calc(20rem / 16);
    padding-right: calc(40rem / 16);
}

.form-control-icon--lg {
    font-size: calc(16rem / 16);
}

.form-control.form-control--with-payment-icon {
    padding-left:3.5rem;
}
.form-control__payment-icon {
    position: absolute;
    left: 0;
    top: .75rem;
    padding: 0 calc(20rem / 16);
    font-size: calc(20rem / 16);
    background: none;
    border: 0;
    color: currentColor;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        top: .875rem;
    }
}
.form-control__payment-icon>img {
    position: relative;
    top: -.25rem;

    @media screen and (min-width: 768px) {
        top: -.375rem;
    }
}