.cart__image {
    height: 80px;
    width: 80px;
    background-color: #F9F8F7;
    padding:.5rem;

    @media screen and (min-width: 768px) {
        height: 150px;
        width: 150px;
        margin-right: 3rem;
        padding:.875rem;

    }
}

.btn.btn-voucher-add,
.btn.btn-voucher-remove {
    padding-left:1.375rem;
    padding-right:1.375rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-voucher-add>.icon {
    transform: rotate(-45deg);
}



@media screen and (max-width: 767px) {
    .cart__delete-mobile {
        position: absolute;
        right:0;
        top:-.125rem;
    }
}
