@import "../libs/photoswipe/dist/photoswipe";

.pswp {
    --pswp-bg: #fff;
    --pswp-placeholder-bg: #fff;
}

.pswp__top-bar {
    top: 0;
    right: 0;

    height: calc(40rem/16);
}

.pswp__button {
    width: calc(40rem/16);
    height: calc(40rem/16);
}

.pswp__icn {
    position: static;

    width: calc(24rem/16);
    height: calc(24rem/16);

    fill: #999;
}

.pswp__button--zoom {
    align-items: center;
    justify-content: center;
}

.pswp--zoom-allowed .pswp__button--zoom {
    display: flex;
}

.pswp__icn--zoom-out {
    display: none;
}

.pswp--zoomed-in .pswp__icn--zoom-in {
    display: none;
}

.pswp--zoomed-in .pswp__icn--zoom-out {
    display: block;
}

.pswp__button--close,
.pswp__button--close:hover,
.pswp__button--close:focus,
.pswp__button--close:active {
    display: flex;
    align-items: center;
    justify-content: center;

    width: calc(40rem/16);
    height: calc(40rem/16);

    margin: 0;

    color: var(--color-text-default);

    opacity: 1;
}

.pswp__button--close .pswp__icn {
    transform: rotate(45deg);
}

.pswp__button--arrow {
    left: calc(20rem/16);
    top: 50%;
    bottom: auto;
    right: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;

    transform: translateY(-50%) rotate(180deg);
    cursor: pointer;
}

.pswp__button--arrow--next {
    left: auto;
    right: calc(20rem/16);
}

.pswp__button--arrow .pswp__icn {
    width: calc(22rem/16);
    height: calc(22rem/16);

    margin: auto;
}

.pswp__product-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    line-height: inherit;

    margin: 0;
    padding: calc(10rem/16) calc(40rem/16);

    text-align: center;
    z-index: 1;
}
