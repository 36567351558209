.bottle-animation {
    height: calc(224rem / 16);
    width: 100%;
    max-width: calc(662rem / 16);
    position: relative;
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
        height: calc(350rem / 16);
    }
}
.bottle-animation__item {
    position: absolute;
}

/* items */
.bottle-animation__item--1 {
    left: -3%;
    bottom: -24%;
    width: 53%;

    @media screen and (min-width: 768px) {
        left: 2%;
        bottom: -22%;
        width: 48%;
    }
}
.bottle-animation__item--2 {
    bottom: -21%;
    right: -6%;
    width: 46%;

    @media screen and (min-width: 768px) {
        bottom: -17%;
        right: 0;
        width: 40%;
    }
}
.bottle-animation__item--bottle-1 {
    left: 8%;
    bottom: -14%;
    width: 54%;

    @media screen and (min-width: 768px) {
        left: 12%;
        bottom: -3%;
        width: 47%;
    }
}
.bottle-animation__item--bottle-2 {
    right: -4%;
    bottom: -16%;
    width: 56%;

    @media screen and (min-width: 768px) {
        right: 3%;
        bottom: -4%;
        width: 48%;
    }
}
.bottle-animation__item--5 {
    left: 25%;
    bottom: -20%;
    width: 59%;

    @media screen and (min-width: 768px) {
        width: 53%;
        left: 27%;
        bottom: -15%;
    }
}