@media screen and (max-width: 767px) {
    .sticky-button-bar {
        z-index: 2;
        left:0;
        right:0;
        bottom:0;
        width: auto;
        padding: 0 calc(20rem / 16) calc(12rem / 16);
    }

    .sticky-button-bar .btn {
        box-shadow: 0 0 calc(12rem / 16) calc(2rem / 16) rgba(0,58,96,0.15);
    }
}