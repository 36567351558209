.history-slider__control {
    position: relative;
    margin-bottom: calc(24rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(25rem / 16);
    }

    @media screen and (min-width: 1024px) {
        margin-bottom: calc(52rem / 16);
    }
}

.history-slider__arrows--wrapper {
    display: none;

    @media screen and (min-width: 992px) {
        display: flex;
        width: 100%;
        position: absolute;
        top: 0;
    }
}

.history-slider__arrows--wrapper .js-scrolling-area__prev,
.history-slider__arrows--wrapper .js-scrolling-area__next {
    position: absolute;
    top: 37px;
    width: 70px;
    height: 396px;
    background-color: rgba(225,225,225,0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1400px) {
        height: 410px;
    }
}

.history-slider__arrows--wrapper .js-scrolling-area__prev {
    left: 0;
}

.history-slider__arrows--wrapper .js-scrolling-area__next {
    right: 0;
}

.history-slider__arrows .icon-arrow-left,
.history-slider__arrows .icon-arrow-right {
    color: var(--color-primary);
    font-size: 3rem;
    font-weight: 700;
}

.history-slider__arrows .icon-arrow-right {
    margin-left: 10px;
}

.history-slider__arrows .icon-arrow-left {
    margin-right: 10px;
}

.history-slider__arrows button[disabled="true"] .icon-arrow-left,
.history-slider__arrows  button[disabled="true"] .icon-arrow-right {
    color: var(--color-light-dark);
}


.history-slider__slider {
    padding: 0 calc(40rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(calc(100vw - calc(564rem / 16) - calc(60rem / 16))/2);
    }

    @media screen and (min-width: 1400px) {
        padding: 0 min(30%, calc(calc(100vw - calc(564rem / 16) - calc(160rem / 16))/2));
    }
}
.history-slider__item {
    padding: 0 calc(10rem / 16);
    scroll-snap-align: center;
    opacity: .5;
    transition: opacity 200ms ease-in-out;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        padding: 0 calc(40rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: 0 calc(80rem / 16);
    }
}
.history-slider__item:hover {
    opacity: .7;
}
.history-slider__item.is-active {
    opacity: 1;
}
.history-slider__item__content {
    margin-top: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem / 16);
    }
}

.history-slider__item__title {
    font-size: calc(16em / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    letter-spacing: normal;
}

.visible-active {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.history-slider__item.is-active .visible-active {
    opacity: 1;
}