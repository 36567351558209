.btn.icon-btn,
.icon-btn {
    width: calc(48rem / 16);
    height: calc(48rem / 16);
    padding: calc(5rem / 16);
    position: relative;
    font-size: calc(14rem / 16);
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.icon-btn__icon.icon-rotate-180 {
    transform: translate(-50%, -50%) rotate(180deg);
}
.btn.icon-btn--lg,
.icon-btn--lg {
    width: calc(48rem / 16);
    height: calc(48rem / 16);
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        width: calc(60rem / 16);
        height: calc(60rem / 16);
    }
}
.btn.icon-btn--sm,
.icon-btn--sm {
    width: calc(35rem / 16);
    height: calc(35rem / 16);
    font-size: calc(12rem / 16);
}