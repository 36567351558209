.banner-text-image__box-container {
    z-index: 2;
}

.banner-text-image__box-container-sub-headline {
    line-height: 1rem;
    font-size: calc(12rem / 16);
    margin-bottom: 0.5rem;
}

.banner-text-image__container {
    position: relative;
    margin-bottom: -3rem;

    @media screen and (min-width: 768px) {
        position: static;
        margin-bottom: 0;
    }
}

.banner-text-image__row {
    @media screen and (min-width: 768px) {
        position: relative;
    }
}

.banner-text-image__picture {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.banner-text-image__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.banner-text-image__container-text-wrapper {
    position: relative;
    z-index: 2;
    padding-bottom: 3rem;
    text-shadow: 1px 1px 2px var(--color-text-default);

    @media screen and (min-width: 768px) {
        padding-bottom: 0;
    }
}