.product-slide__title {
    margin-bottom: calc(-26rem / 16);
    position: relative;
    z-index: 1;
    max-width: 70%;
    font-size: calc(38rem/16);

    @media screen and (min-width: 768px) {
        max-width: 95%;
    }

    @media screen and (min-width: 1400px) {
        margin-bottom: calc(-33rem / 16);
        font-size: calc(56rem/16);
    }
}
.product-slide__content {
    position: relative;
    padding-top: calc(35rem / 16);
    margin-left: 6%;

    @media screen and (min-width: 768px) {
        padding-top: calc(50rem / 16);
        margin-left: 14.6%;
    }

    @media screen and (min-width: 1400px) {
        padding-top: calc(80rem / 16);
    }
}
.product-slide__content__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: calc(-30rem / 16);
    height: 64%;
    background: var(--color-sand-20);

    @media screen and (min-width: 1200px) {
        height: 75%;
    }
}
.product-slide__content__bg.is-custom {
    opacity: .4;
}
.product-slide__item__img {
    transition: transform 200ms ease-in-out;
    filter: drop-shadow(calc(0rem / 16) calc(12rem / 16) calc(8rem / 16) rgba(0,0,0,.2));
}
.product-slide__item:hover .product-slide__item__img {
    transform: translateY(calc(-8rem / 16));
}
.product-slide__item__title {
    font-size: calc(14rem / 16);
    line-height: calc(18/16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    text-transform: none;
    color: var(--color-default);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(16rem / 16);
    }
}
.product-slide__item__img {
    max-width: calc(124rem / 16);

    @media screen and (min-width: 768px) {
        max-width: calc(150rem / 16);
    }

    @media screen and (min-width: 1200px) {
        max-width: calc(180rem / 16);
    }

    @media screen and (min-width: 1400px) {
        max-width: calc(306rem / 16);
    }
}

.product-slide__media {
    z-index: 1;
    margin: 0 calc(-20rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0;
        margin-top: calc(-16rem / 16);
    }
}
.product-slide__media__title {
    font-size: calc(20rem / 16);
    letter-spacing: calc(.5rem / 16);
}
.product-slide__media__img {
    transition: transform 200ms ease-in-out;
}
.product-slide__media:hover .product-slide__media__img {
    transform: scale(1.02);
}


/* media left */
.product-slide--media-left .product-slide__content {
    margin-left: 0;
    margin-right: 6%;

    @media screen and (min-width: 768px) {
        margin-right: 14.6%;
    }
}
.product-slide--media-left .product-slide__content__bg {
    right: 0;
    left: calc(-30rem / 16);
}
.product-slide--media-left .product-slide__title {
    justify-content: flex-end;
    margin-left: auto;
}