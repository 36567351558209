.slide {
    margin-top: calc(40rem / 16);
    margin-bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem / 16);
        margin-bottom: calc(100rem / 16);
    }
}

.slide-sm {
    margin-top: calc(40rem / 16);
    margin-bottom: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
        margin-bottom: calc(60rem / 16);
    }
}

.slide-account,
.slide-checkout {
    margin: calc(40rem / 16) auto;

    @media screen and (min-width: 768px) {
        margin: calc(60rem / 16) auto;
    }
}
.slide-account.slide-account--no-mb,
.slide-account.slide-checkout--no-mb {
    margin-bottom: 0;
}

.slide-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.slide-box--lg {
    @media screen and (min-width: 768px) {
        min-height: calc(450rem / 16);
    }
}
.slide__content{
    padding: calc(42rem / 16) calc(20rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(50rem / 16) calc(20rem / 16);
    }
}
.slide__content__title {
    font-size: calc(30rem/16);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}