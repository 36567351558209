:root {
    --color-primary: #003A60;
    --color-secondary: #6c757d;
    --color-text-default: var(--color-default);
    --color-text-muted: var(--color-dark-grey);
    --color-text-muted-dark: var(--color-sand-dark);
    --color-primary-transparent: rgba(0, 58, 96, 0.9);

    --color-default: #323232;
    --color-white: #FFF;
    --color-light-grey: #F8F9FA;
    --color-dark-grey: #A4A2A1;
    --color-dark: var(--color-default);

    --color-sand-10: #F9F8F7;
    --color-sand-20: #F0EEEC;
    --color-sand: #B5ADA6;
    --color-sand-dark: #756d66;

    --color-green: #323232;
    --color-blue: #003A60;
    --color-yellow: #FFE569;
    --color-red: #E58D7A;

    --color-success: #65BA8C;
    --color-info: var(--color-yellow);
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #DC3545;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #002f4e;
    --color-secondary-dark: #424D56;
    --color-success-dark: #57a078;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #6DA4E2;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "neusa-next-std", sans-serif;
    --font-default-bold: "neusa-next-std", sans-serif;
    --font-default-compact: "neusa-next-std-compact", sans-serif;

    --font-default-weight: 400;
    --font-default-bold-weight: 500;
    --font-default-compact-weight: 400;

    --font-default-compact-style: italic;

    --font-size-default: 1rem;
    --font-size-default-xs: calc(14rem / 16);

    --bs-btn-disabled-border-color: transparent;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-bg: var(--color-sand-10);
    --bs-btn-active-bg: var(--color-sand-10);
}
