:root {
    --main-navbar-height--xs: calc(70rem / 16);
}
@media screen and (max-width: 767px) {
    .main-navbar__main  {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
    }
    .nav-toggle {
        width: calc(40rem / 16);
        height: calc(40rem / 16);
        border: 0;
        background: none;
        position: relative;
        z-index: 10;
        padding: calc(5rem / 16) calc(12rem / 16);
        margin-right: calc(-18rem / 16);
        color: currentColor;
    }
    .nav-toggle__line {
        display: block;
        width: calc(16rem / 16);
        height: calc(2rem / 16);
        background: #fff;
        transition: transform 250ms ease-in-out, opacity 200ms ease;
        transform-origin: left center;
        margin: 0 auto;
    }
    .main-navbar--dark .nav-toggle__line,
    .is-open .nav-toggle__line,
    .main-navbar--relative .nav-toggle__line,
    .is-affix .nav-toggle__line {
        background: var(--color-primary);
    }
    .nav-toggle__line + .nav-toggle__line {
        margin-top: calc(4rem / 16);
    }
    .is-open .nav-toggle__line {
        width: calc(19rem / 16);
        transform: rotate(45deg) translateX(0) translateY(-4px);
    }
    .is-open .nav-toggle__line:nth-child(2) {
        transform: rotate(-45deg) translateX(-1px) translateY(5px);
    }
    .is-open .nav-toggle__line:last-child {
        opacity: 0;
    }
    .main-navbar__container {
        padding: 0 calc(30rem / 16);
        height: var(--main-navbar-height--xs);
        transition: background 200ms ease-in-out;
    }

    .main-navbar--relative .main-navbar__container:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(20rem / 16);
        right: calc(20rem / 16);
        height: calc(1rem / 16);
        background: var(--color-sand-20);
    }
    .is-open .main-navbar__container:after,
    .is-affix .main-navbar__container:after {
        display: none;
    }
    .is-open .main-navbar__container,
    .main-navbar--relative .main-navbar__container {
        color: var(--color-primary);
        background: #fff;
    }

    .mega-nav {
        background-color: #fff;
        color: var(--color-default);
    }
    .main-navbar__right {
        transition: opacity 200ms ease-in-out;
        margin-left: auto;
    }
    .main-navbar.is-open .main-navbar__right {
        opacity: 0;
    }

    .mega-nav__item {
        display: block;
        width: 100%;
    }
    .mega-nav__title-link,
    .mega-nav__item-content {
        display: block;
        width: 100%;
        position: relative;
    }

    /* level 0 */
    .mega-nav--level-0 {
        display: flex;
        flex-direction: column;
        position: fixed;
        right: 0;
        width: 90%;
        margin: 0;
        height: calc(100vh - var(--main-navbar-height--xs));
        max-height: calc(100% - var(--main-navbar-height--xs));
        padding: calc(30rem / 16) calc(30rem / 16) calc(40rem / 16);
        visibility: hidden;
        transform: translateX(100%);
        transition: 120ms ease;
        box-shadow: calc(-20rem / 16) calc(50rem / 16) calc(50rem / 16) 0 rgba(0,0,0,0.2);
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .main-navbar.is-open .mega-nav--level-0 {
        z-index: 9;
        visibility: visible;
        transform: translateX(0);
    }
    .mega-nav__item--level-0 {
        border-top: calc(1rem / 16) solid var(--color-sand-20);
    }
    .mega-nav__item--level-0:last-child {
        border-bottom: calc(1rem / 16) solid var(--color-sand-20);
    }
    .mega-nav__item-content--level-0 {
        padding: calc(18rem / 16) calc(10rem / 16) calc(13rem / 16);
        font-size: calc(16rem / 16);
    }
    .is-open > .mega-nav__item-content--level-0 {
        color: var(--color-primary);
    }


    /* Level 1 */
    .mega-nav--level-1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
    }
    .mega-nav__item.is-open > div > .mega-nav--level-1 {
        transform: translateX(0);
    }
    .mega-nav__item-content--level-1 {
        font-size: calc(14rem / 16);
    }
    .mega-nav__item--level-1 + .mega-nav__item--level-1 {
        margin-top: calc(12rem / 16);
    }
    .mega-nav__top__btn {
        background: var(--color-sand-10);
        height: calc(60rem / 16);
        padding: calc(5rem / 16) calc(40rem / 16);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: calc(16rem / 16);
        width: 100%;
        border: 0;
    }
    .mega-nav__top__btn__arrow {
        position: absolute;
        left: calc(30rem / 16);
        top: 50%;
        transform: translateY(-50%);
        font-size: calc(12rem / 16);
        color: var(--color-sand);
    }
    .mega-nav__content {
        padding: calc(26rem / 16) calc(30rem / 16) calc(50rem / 16);
        height: calc(100% - calc(60rem / 16));
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .mega-nav__title {
        margin-bottom: calc(22rem / 16);
    }

    .mega-nav__content-col {
        position: relative;
    }
    .mega-nav__content-col + .mega-nav__content-col:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(10rem / 16);
        right: calc(10rem / 16);
        height: calc(1rem / 16);
        background: var(--color-sand-20);
    }
    .mega-nav__content-col + .mega-nav__content-col {
        margin-top: calc(30rem / 16);
        padding-top: calc(30rem / 16);
    }

    .mega-nav__bottom {
        font-size: calc(12rem / 16);
        padding-top: calc(30rem / 16);
        padding-bottom: calc(10rem / 16);
    }
}

.mega-nav__collapse-icon {
    @media screen and (min-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: block;
        font-size: calc(12rem / 16);
        transition: transform 120ms ease-out;
        transform-origin: center center;
        position: absolute;
        top: 50%;
        right: calc(12rem / 16);
        transform: translateY(-50%);
    }
}