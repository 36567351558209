:root {
    --ejb-list-border-color: var(--color-sand);
    --ejb-list-head-background-color: #fff;
    --ejb-list-cell-background-color-hover: var(--color-sand-20);
    --ejb-list-ara__form-group-label-color: var(--color-sand-20);
}

.ejb-list {
    width: 100%;
    max-width: 100%;
    background-color: transparent;

@media screen and (max-width: 767px) {
    display: block;
}
}

.ejb-list-area__title {
    margin-bottom: 0;
}

.ejb-list-ara__filter {
    margin: 0;
}

.ejb-list-ara__form-group {
    margin-bottom: calc(16rem /16);
    @media screen and (min-width: 768px) {
        margin: 0;
    }
}

.ejb-list-ara__form-group-label {
    font-size: calc(10rem /16);
    color: var(--color-text-grey);
}

.ejb-list__head {
    color: #000;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        display: none;
    }
    @media screen and (min-width: 768px) {
        background-color: var(--ejb-list-head-background-color);
        font-size: .85em;
        font-family: var(--font-default-bold);
    }
}

.ejb-list__body {
@media screen and (max-width: 767px) {
    display: block;
}
}

.ejb-list__cell {
    font-family: inherit;
    font-weight: inherit;
    @media screen and (max-width: 767px) {
        display: block;
        grid-column: 1;
    }
    @media screen and (min-width: 768px) {
        vertical-align: middle;
        width: 1px;
        white-space: nowrap;
        padding: calc(.5vw + .5rem) calc(1.5vw + .5rem) calc(.5vw + .5rem) 0;
        border-bottom: 1px solid var(--ejb-list-border-color);
    }
}

tr td.ejb-list__cell--main {
    @media screen and (min-width: 768px) {
        width: 50%;
        white-space: normal;
    }
}

td.ejb-list__cell--detail {
    padding-right: 0;
    text-align: right;
@media screen and (max-width: 767px) {
    grid-column: 2;
    grid-row: 1 / 100 ;
    align-self: center;
}
}

td.ejb-list__division {
    @media screen and (min-width: 768px) {
        width: 25%;
    }
}

td.ejb-list__cell--location {
    @media screen and (min-width: 768px) {
        width: 25%;
    }
}

.ejb-list__item-title {
    font-size: calc(16rem / 16);
    padding: 0;
    margin: 0;
    @media screen and (max-width: 767px) {
        padding-bottom: .75em;
        border-bottom: 1px solid var(--ejb-list-border-color);

        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: .5rem;
        grid-auto-flow: column;
        grid-auto-rows: auto;
    }
}

.ejb-list__item + .ejb-list__item {
@media screen and (max-width: 767px) {
    margin-top: .75em;
}
}
.ejb-list__item:hover {
@media screen and (min-width: 768px) {
    background-color: var(--ejb-list-cell-background-color-hover);
    cursor: pointer;
}
}

.ejb-list__item-title-link {
    vertical-align: sub;
}

.ejb-list__item-detail-link {
    display: inline-block;
    padding: 1rem;
    margin: -1rem;
    margin-right: 0;
    font-size: calc(10rem / 16);
}

.li-has-arrow {
    font-size: calc(10rem / 16);
}

.jobs__apply {
    background-color: var(--color-background-grey);
}

/* custom css before pimcore x */
@media screen and (max-width: 768px) {
    .jobs-container h3.underline {
        font-size: 1.375rem;
    }

    .jobs-container .vertical-gutter--4 > .vertical-gutter__item {
        margin-top: 1.75rem;
    }

    .jobs-container .vertical-gutter--4 > .vertical-gutter__item > h2 {
        font-size: 1.1875rem;
    }

    .jobs-container .vertical-gutter--4 > .vertical-gutter__item > h2 ~ div.wysiwyg {
        margin-top: 15px;
    }

    .jobs-container .wysiwyg > p ~ ul {
        margin-top: -15px;
    }
}