.disable-collapse-mobile.collapse:not(.show) {
    @media screen and (max-width: 767px){
        display: block!important;
        height: auto !important;
    }

    @media screen and (min-width: 768px){
        display: block!important;
        height: 300px !important;
        overflow: hidden;
    }
}

.seo-collapse.collapse:not(.show) {
    display: block !important;
    height: 300px !important;
    overflow: hidden;
}

.disable-collapse-mobile.collapsing,
.seo-collapse.collapsing {
    min-height: 300px !important;
}

.seo-collapse-md.collapse:not(.show) {
    @media screen and (min-width: 768px){
        display: block!important;
        height: 300px !important;
        overflow: hidden;
    }
}

.seo-collapse-md.collapsing {
    @media screen and (min-width: 768px){
        min-height: 300px !important;
    }
}

.seo-collapse-md--reduced.collapse:not(.show) {
    @media screen and (min-width: 768px){
        display: block!important;
        height: 180px !important;
        overflow: hidden;
    }
}

.seo-collapse-md--reduced.collapsing {
    @media screen and (min-width: 768px){
        min-height: 180px !important;
    }
}

.pds-collapse-md.collapse:not(.show) {
    display: block !important;

    @media screen and (min-width: 768px){
        height: 680px !important;
    }
}

.pds-collapse-md.collapsing {
    @media screen and (min-width: 768px){
        min-height: 680px !important;
    }
}

.disable-collapse-md.collapse:not(.show),
.disable-collapse-md.in,
.disable-collapse-md.collapsing {
    @media screen and (min-width: 768px){
        display: block!important;
        height: auto !important;
        max-height: none !important;
    }
}

.bg-md-grey{
    @media screen and (min-width: 768px){
        background-color: var(--color-background-grey) !important;
    }
}
.range-of-use{
    height: 24px
}

.range-of-use--border{
    margin-left: calc(11rem / 16);
    margin-right: calc(11rem / 16);
}

.bg-gradient{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.range-of-use__container{
    @media screen and (min-width: 768px){
        width: 20%;
        max-width: 110px;
    }

    @media screen and (max-width: 767px){
        flex-grow: 1;
    }
}

.range-of-use__container-text{
    font-size: 14px;
    @media screen and (max-width: 767px){
        font-size: 12px;
    }
}

.border.border-grey,
.border-top.border-grey,
.border-bottom.border-grey{
    border-color: var(--color-border-grey) !important;
}

.border-md-right{
    @media screen and (min-width: 768px){
        border-right: 1px solid #DEDEDE;
    }
}

.customer-rating{
    margin-bottom: 50px;
    overflow: hidden;

    @media screen and (max-width: 767px){
        margin-bottom: 30px;
    }
}

.customer-rating--answer {
    margin-top: -28px;

    @media screen and (max-width: 767px){
        margin-top: 0;
    }
}

.customer-rating__head {
    font-size: 13px;
    line-height: 16px;
    color: var(--color-middle-grey);
}

.customer-rating__text{
    border-bottom: 1px solid var(--color-default);
    font-size: 14px;
    line-height: 24px;
    padding: 15px 0;

    @media screen and (min-width: 768px){
        max-width: 100%;
        padding: 7px 0 15px 0;
        margin: 0 auto;
    }
}

.customer-rating__customer {
    font-size: 14px;
    line-height: 24px;
    position: relative;
    padding: 1.25rem 0.5rem 0 3.125rem;
}

.customer-rating--answer .customer-rating__customer {
    padding: 1.25rem 3.125rem 0 0.5rem;
    text-align: right;
}

.product-rating__not-rated {
    font-size: 13px;
    line-height: 16px;
    position: relative;
    color: var(--color-text-grey);
    border-bottom: 1px solid var(--color-default);
    padding-bottom: 7px;
    margin-bottom: 30px;
}

.customer-rating__customer:after,
.product-rating__not-rated:after {
    position: absolute;
    content: '';
    top: -1px;
    left: 1.25rem;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('/static/img/ortovox/speech-bubble-dark.svg');
    background-size: 1.5rem;
}

.customer-rating--answer .customer-rating__customer:after {
    left: auto;
    right: 1.25rem;
    transform: scaleX(-1);
}

.product-rating__not-rated:after {
    left: auto;
    right: 1.5rem;
    bottom: -1.5rem;
    top: auto;
    transform: scaleX(-1);
}

.customer-rating__container .pds__accordion-wrapper {
    border-bottom: none;
}

.customer-rating__container .seo-collapse.collapse:not(.show) {
    height: auto!important;
    max-height: 300px;
}

.product-rating__rating {
    @media screen and (max-width: 767px) {
        margin-bottom: 28px;
    }
}

.product-rating__button .btn {
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
    }
}

.customer-rating__container{
    margin-top: 30px;

    @media screen and (min-width: 768px) {
        margin-top: 60px;
        max-height: 550px;
    }
}

.customer-rating__rating {
    @media screen and (max-width: 767px) {
        margin-top: 15px;
        margin-right: 25px;
    }
}

.customer-rating__container::-webkit-scrollbar {
    width: 5px;
}

.customer-rating__container::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: white;
}

.customer-rating__container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #a5acaf;
}

.product-rating{
    line-height: 1;
    padding-top: 5px;

    @media screen and (min-width: 768px){
        margin-bottom: calc(50rem / 16);
    }
}

.pds__addon-slider .product-rating{
    margin-bottom: 0;
}

.pds__addon-slider .slick-track{
    margin-left: 0 !important;
    display: flex !important;
}

.pds__addon-slider .slick-slide{
    height: inherit !important;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 767px){
        width: 73.6vw;
    }
}
/*make sure all slides are the same height*/
.pds__addon-slider .slick-slide > div,
.pds__addon-slider .slick-slide > div > div,
.pds__addon-slider .slick-slide > div > div > div {
    display: flex !important; /* override inline style */
    flex-direction: column;
    flex: auto;
}
.pds__addon-slider .slick-arrow {
    top: 37%;
}
.pds__addon-slider .slick-prev {
    left: .5rem;
}
.pds__addon-slider .slick-next {
    right: .5rem;
}

.pds__addon-slider .pds__addon-slider__box {
    padding-right: 18px;

    @media screen and (min-width: 768px){
        padding-right: 20px;
    }
}

.slick-slide:last-child .pds__addon-slider__box {
    padding-right: 0;
}

.pds-video-teaser{
    @media screen and (min-width: 768px){
        margin-left: 88px;
    }
}

.pds-small-text{
    font-size: calc(14rem / 16);
}

.pds__title {
    @media screen and (max-width: 767px){
        font-size: calc(33rem / 16);
    }
}

.pds-store-availability-spacing{
    margin-top: calc(32rem / 16);

    @media screen and (max-width: 767px){
        margin-top: calc(16rem / 16);
    }
}

.pds__detail-headline {
    font-size: calc(26rem / 16);

    @media screen and (min-width: 768px){
        font-size: calc(33rem / 16);
    }
}

.pds__product-slider-thumb .pds__product-slider-thumb-image{
    border-color: var(--color-border-grey);
}

.pds__product-slider-main {
    @media screen and (min-width: 768px) {
        width: 100%;

        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 1200px){
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 1440px){
        padding-left: 15px;
        padding-right: 15px;
    }
}


.pds__product-slider-main--highlights{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        left: 15px;
        right: 15px;
    }
}

.pds__product-slider-main-btn{
    pointer-events: auto;
}

.pds-slider-small__btns{
    font-size: 10px;
}

.pds-slider-small__btns.slick-prev{
    position: absolute;
    top: 0;
    bottom: initial;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    width: 100%;
}

.pds-slider-small__btns.slick-next{
    position: absolute;
    bottom: 0;
    top: initial;
    left: 0;
    right: 0;
    transform: translateY(100%);
    width: 100%;
}

.additional-info__button:hover{
    text-decoration: none;
}

.pds__product-slider__item-inner {
    background-color: var(--color-background-grey);
}

.outtra-place-display {
    font-weight: 400;
}

#outtra-local-inplace-container#outtra-local-inplace-container {
    margin-top: 50px;
    font-size: 13px;

    @media screen and (min-width: 768px) {
        margin-top: 45px;
    }
}

#outtra-local-inplace-container #js-show_place_search {
    font-family: var(--font-default-bold);
    font-weight: 400;
}

#js-show_place_search_container {
    padding-right: 20px;
}

#outtra-shop-suggestions-list li {
    margin-bottom: 6px;

    color: inherit;
    line-height: 1;
}

#outtra-shop-suggestions-list .outtra-shop-suggestion-name {
    font-family: var(--font-default-bold);
    font-weight: 400;

    font-size: var(--font-size-default);
    line-height: 1.2;

    margin-bottom: 2px;
}

#outtra-shop-suggestions-list .outtra-shop-suggestion-name + div {
    line-height: 1.5;
}

#outtra-shop-suggestions-list .outtra-list-distance-marker {
    top: 50%;
    transform: translateY(-50%);
}

#outtra-shop-suggestions-list .outtra-list-distance-marker img {
    height: 10px;
    margin-top: -1px;
}

#outtra-shop-suggestions-list .outtra-shop-suggestion-list-item:last-child {
    margin-bottom: 0;
}

.pds__product-attribute {
    margin-bottom: 18px;
}

.pds__product-attribute:last-child {
    margin-bottom: 0;
}

.pds__product-attribute--grid + .pds__product-attribute--grid {
    margin-top: -18px;
}

.pds__product-attribute__prefix {
    max-width: calc(80rem / 16);
    flex-basis: calc(80rem / 16);
}
