.anchor-menu {
    position: fixed;
    right: 0;
    bottom: 15%;
    z-index: 11;

    @media screen and (min-width: 768px) {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}
.anchor-menu__link {
    padding: calc(10rem / 16) calc(10rem / 16) calc(9rem / 16);
    border-top-left-radius: calc(4rem / 16);
    border-bottom-left-radius: calc(4rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(17/14);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        padding: calc(16rem / 16) calc(20rem / 16) calc(15rem / 16);
    }
}
.anchor-menu__link__icon {
    font-size: calc(10rem / 16);
    margin-right: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(10rem / 16);
    }
}