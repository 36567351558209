.slide-account h1,
.slide-checkout h1 {
    margin:0 0 .5rem 0;
    @media screen and (min-width: 768px) {
        margin:0 0 1.5rem 0;
    }
}
.slide-account h1 + .back-link,
.slide-checkout h1 + .back-link {
    margin:0 0 1.5rem 0;

    @media screen and (min-width: 768px) {
        margin:0 0 2.5rem 0;
    }
}
.slide-account form button[type=submit],
.slide-checkout form button[type=submit] {
    display: flex;
    margin-left: auto;
}
@media screen and (max-width: 767px) {
    .slide-account form button[type=submit].btn-primary--xs-no-align,
    .slide-checkout form button[type=submit].btn-primary--xs-no-align {
        display: block;
        margin: 0 auto;
    }
}



.checkout-summary__total-title,
.checkout-summary__total-price{
    font-size:1.5rem;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);
    @media screen and (max-width: 767px) {
        font-size:1.125rem;
    }
}


.checkout-overview-data {
    margin:3.5rem 0;
    font-size: .875rem;
}
.checkout-overview-data__hl {
    font-size: 1.125rem;
    letter-spacing: calc(1rem / 16);
}
.success-box {
    padding:1.25rem;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 50px 0 rgba(0,58,96,0.1);
    @media screen and (min-width: 768px) {
        padding:2rem;
    }
}


.customer-hotline {
    position: relative;
    left:50%;
    transform: translateX(-50%);
    padding:3rem 1rem 1rem;
    font-size: .875rem;
    background: var(--color-light-grey);
}
.customer-hotline:before {
    content:'';
    z-index: -1;
    position: absolute;
    top:0;
    bottom:0;
    left:50%;
    transform: translateX(-50%);
    width:100vw;
    background: var(--color-light-grey);
}


/* account selection */
.account-select-box {
    position: relative;
    margin:0 0 4.5rem 0;

    @media screen and (min-width: 768px) {
        margin:0;
        height:100%;
        display: flex;
        flex-direction: column;
    }
}
.account-select-box__body {
    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
    }
}
.account-select-box__body .floating-label  {
    display: none;
}
.account-select-box__hl {
    font-size: 1.25rem;
}
.account-select-box .btn.btn {
    display: inline-block;
    margin: 0 auto;
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
    @media screen and (min-width: 768px) {
        min-width:18rem;
    }
}

.unzerChoices.unzerChoices,
.unzerUI.unzerUI {
    font-family: inherit;
}
