.btn {
    border-radius: calc(4rem / 16);
    padding: calc(15rem / 16) min(10%, calc(38rem / 16)) calc(11rem / 16);
    text-transform: uppercase;
    font-size: calc(14rem / 16);
    line-height: calc(17/14);
    box-shadow: none;
    font-family: var(--font-default-bold);
    font-weight: var(--font-default-bold-weight);

    @media screen and (min-width: 768px) {
        padding: calc(16rem / 16) calc(38rem / 16) calc(13rem / 16);
    }
}
/* button sizes */
.btn.btn-lg {
    @media screen and (min-width: 768px) {
        padding: calc(21rem / 16) calc(38rem / 16) calc(18rem / 16);
    }
}
.btn.btn-xl {
    @media screen and (min-width: 768px) {
        font-size:1rem;
        padding: 1.5rem 4.5rem;
    }
}
.btn.btn-sm {
    font-size: calc(12rem / 16);
    padding: calc(10rem / 16) calc(20rem / 16);
    text-transform: none;
}

.btn.btn-link {
    padding: 0;
}
.btn-block {
    width: 100%;
}
.btn-unstyled {
    padding:0;
    margin:0;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
/* colors */
.btn-white {
    background: #fff;
    color: var(--color-primary);
}
.btn-white:hover,
.btn-white:focus {
    background: var(--color-primary-light);
    color: #fff;
}
.btn-white:disabled {
    opacity: 1;
    color: var(--color-text-muted);
}
.btn-primary:hover,.btn-primary:focus {
    background: var(--color-primary-light);
    border-color: var(--color-primary-light);
    color: var(--color-primary-contrast);
}
.btn-default {background: var(--color-sand-20); color: var(--color-text-default)}
.btn-default:hover, .btn-default:focus {background: var(--color-sand); color: var(--color-text-default)}
.btn-success {color: #fff;}
.btn-success:hover,.btn-success:focus {color: #fff; background: var(--color-success-dark);}
.btn-sand-10 {color: var(--color-primary); background: var(--color-sand-10);}
.btn-sand-10:hover,.btn-sand-10:focus {color: var(--color-primary); background: var(--color-sand-20);}

.btn-shadow {
    box-shadow: 0 calc(10rem / 16) calc(50rem / 16) 0 rgba(0,58,96,0.1);
}
.btn-shadow-sm {
    box-shadow: 0 0 calc(15rem / 16) 0 rgba(0,58,96, 0.08);
}

/* button with icon */
.btn--with-icon {
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        padding-left: calc(24rem / 16);
        padding-right: calc(24rem / 16);
    }
}
.btn__icon {
    font-size: calc(16rem / 16);
    margin-right: calc(12rem / 16);
    margin-top: calc(-4rem / 16);
}

.btn-show-more {
    background: #fff;
    border: 0;
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
    text-transform: none;
    letter-spacing: normal;
    font-size: calc(14rem / 16);
    padding: calc(18rem / 16) calc(30rem / 16) calc(14rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(16rem / 16);
    }
}
.btn-show-more__toggle {
    position: absolute;
    right: calc(8rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-dark-grey);
    font-size: calc(8rem / 16);
}
.btn-show-more__icon {
    width: calc(30rem / 16);
    height: auto;
    margin-top: calc(-2rem / 16);
    margin-right: calc(10rem / 16);
}

@media screen and (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    .xs-pos-sticky-bottom {
        position: sticky;
        left:0;
        right:0;
        bottom:0;
        width: auto;
        padding:.75rem 0;
        background: #fff;
        z-index: 6;
    }
    .xs-pos-sticky-bottom .btn {
        display: block;
        width: 100%;
    }
}
.btn-success.disabled, .btn-success:disabled {
    color: #fff;
}

/* cart button */
.btn-cart__default {
    transition: opacity 200ms ease-in-out;
}
.btn-cart-dark {
    background-color: #286459;
}
.is-finished .btn-cart__default,
.is-loading .btn-cart__default {
    opacity: 0;
}
.btn-cart__finished {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 200ms ease-in-out;
    font-size: calc(22rem / 16);
}
.is-finished .btn-cart__finished {
    opacity: 1;
}

/* focus styles */
.btn:focus:not(:focus-visible),
.accordion-button:focus:not(:focus-visible) {
    box-shadow: none;
}

.select-btn {
    background-color: var(--color-sand-10);
    font-size: calc(12rem / 16);
    line-height: calc(16/14);
    padding: calc(15rem / 16) calc(15rem / 16) calc(10rem / 16);
    border-bottom: calc(4rem / 16) solid transparent;
    border-radius: calc(4rem / 16);
    transition: background-color 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16) calc(30rem / 16) calc(18rem / 16);
        font-size: calc(14rem / 16);
    }
}
.select-btn:hover {
    background-color: var(--color-sand-20);
}
.select-btn.is-active {
    border-bottom-color: var(--color-primary);
}